import { apiRequest } from './api';

export async function updateUserPassword(data) {
	try {
		await apiRequest('/user/profile/password', 'PATCH', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserTransactionsAPI(kind, cardId, currency) {
	try {
		const response = await apiRequest('/user/transactions/' + kind + '/' + cardId + '/' + currency, 'GET');
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function getUserProfileAPI() {
	try {
		const response = await apiRequest('/user/profile', 'GET');
		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}

export async function updateUserAddressAPI(data) {
	try {
		await apiRequest('/user/profile/address', 'PATCH', data);
		return { isSuccess: true };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
}
