import { useState } from 'react';

import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from '../modals/custom_modal';
import Loader from '../../pages/utils/loader';
import { createVerestroIbanAPI } from '../../services/ibans';
import { PrimaryButton } from '../buttons/primary-button';
import { SecondaryButton } from '../buttons/secondary-button';

export const IbanBalance = ({ ibanBalance }) => {
	const navigate = useNavigate();

	// const hasIban = localStorage.getItem('hasIban');
	const hasIban = JSON.parse(localStorage.getItem('hasIban'));

	const [isLoading, setIsLoading] = useState(false);

	const [isIbanModalOpen, setIsIbanModalOpen] = useState(false);
	const [isIbanSuccessOpen, setIsIbanSuccessOpen] = useState(false);
	const [isIbanFailOpen, setIsIbanFailOpen] = useState(false);

	const [agreeToTerms, setAgreeToTerms] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const createVerestroIBAN = async () => {
		// add error when not agreed
		if (!agreeToTerms) return setErrMsg('Please agree the terms & conditions');

		if (!isLoading) {
			setIsLoading(true);
			const response = await createVerestroIbanAPI();
			if (!response.isSuccess) {
				setIsLoading(false);
				if (response.error === 'NOT_ALLOWED_BY_COUNTRY') {
					setIsIbanModalOpen(false);
					setIsIbanFailOpen(true);
					return;
				}
				setErrMsg(response.error ?? 'Error creating IBAN please contact support');
				return;
			}
			localStorage.setItem('hasIban', true);
			setIsIbanSuccessOpen(true);
			setIsIbanModalOpen(false);
		}
		setIsLoading(false);
	};

	console.log(hasIban, ' HAS IBAN?');
	console.log(typeof hasIban, ' type of HAS IBAN');
	return (
		<div>
			{!hasIban || hasIban === 'false' ? (
				<div className="flex justify-between bg-lightGray p-5 mt-[10px] rounded-[10px] w-full" onClick={() => setIsIbanModalOpen(true)}>
					<div>
						<p className="text-sm text-darkGray">Don't have an IBAN yet?</p>
						<h1 className="font-bold text-xxl text-gradient">Request IBAN</h1>
					</div>
					<img src="/assets/icons/right_arrow_black.svg" />
				</div>
			) : (
				<div className="flex justify-between bg-lightGray p-5 mt-[10px] rounded-[10px] w-full" onClick={() => navigate('/transactions/iban')}>
					<div>
						<p className="text-sm text-darkGray">IBAN Account Balance</p>
						<h1 className="text-xxl text-black">€{numeral(ibanBalance).format('0,0.00')}</h1>
					</div>
					<img src="/assets/icons/right_arrow_black.svg" />
				</div>
			)}

			<CustomModal
				isOpen={isIbanModalOpen}
				onClose={() => {
					setIsIbanModalOpen(false);
					setErrMsg('');
				}}
				title="Apply for a EUR IBAN account"
				subTitle="Please agree the terms & conditions"
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							<div className="text-sm flex">
								<input
									type="checkbox"
									id="termsCheckbox"
									checked={agreeToTerms}
									onChange={() => setAgreeToTerms(!agreeToTerms)}
									className="bg-darkGray"
								/>
								<label htmlFor="termsCheckbox" className="ml-2 text-darkGray">
									I agree to the
									<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/iban/terms', '_blank')}>
										Terms & Conditions
									</span>
								</label>
							</div>
							<div className="flex flex-col justify-center mt-[20px]">
								<PrimaryButton onClick={createVerestroIBAN} text="Apply" textColor="text-white" />
								<SecondaryButton
									onClick={() => {
										setErrMsg('');
										setIsIbanModalOpen(false);
									}}
									text="Close"
									textColor="text-black"
								/>
							</div>
							<p className="text-red text-center mt-2">{errMsg}</p>
						</div>
					)
				}
			/>

			<CustomModal
				isOpen={isIbanSuccessOpen}
				onClose={() => setIsIbanSuccessOpen(false)}
				title="Congratulations!"
				subTitle="Your IBAN account has been created succuessfuly"
				children={
					<div className="flex justify-center">
						<PrimaryButton
							onClick={() => {
								setIsIbanSuccessOpen(false);
								window.location.reload();
							}}
							text="Close"
							textColor="text-white"
						/>
					</div>
				}
			/>

			<CustomModal
				isOpen={isIbanFailOpen}
				onClose={() => setIsIbanFailOpen(false)}
				title="IBAN Requirements"
				subTitle="In order to have an EUR IBAN account you need to have an address in the EU"
				children={
					<div className="flex flex-col justify-center">
						<p className="text-red mb-5">
							* If you don't have an address in the EU, you can still operate from your debit card to receive and send funds
						</p>
						<PrimaryButton
							onClick={() => {
								navigate('/profile/address/edit');
							}}
							text="Update Address"
							textColor="text-white"
						/>
						<SecondaryButton
							onClick={() => {
								setIsIbanFailOpen(false);
							}}
							text="Close"
							textColor="text-black"
						/>
					</div>
				}
			/>
		</div>
	);
};
