import numeral from 'numeral';

export default function CardAndIbanTransactionItem({ transaction }) {
	return (
		<div>
			<div className="p-5 mb-[10px]">
				<div className="flex justify-between mb-[10px]">
					<h1 className="text-lg text-gradient">{transaction.type}</h1>
					<p className="text-sm">
						Status - <span className={`${transaction.status === 'completed' ? 'text-green' : 'text-orange'}`}>{transaction.status}</span>
					</p>
				</div>
				<div className="flex justify-between">
					<div className="flex">
						<img src="/assets/transactions/transfer.svg" className="me-[10px]" />
						<div className="flex flex-col text-xsm justify-center">
							{transaction.fromCardLastDigits && transaction.type !== 'Card To Card Receive' && (
								<p className="flex">
									<img src="/assets/icons/half_arrow_red.svg" className="me-1" /> Card: **** {transaction.fromCardLastDigits}
								</p>
							)}

							{transaction.toCardLastDigits && (
								<p className="flex">
									<img src="/assets/icons/half_arrow_green.svg" className="me-1" /> Card: ****
									{transaction.toCardLastDigits}
								</p>
							)}
							{transaction.description && <p>{transaction.description}</p>}
							{transaction.paymentMethod && <p>Method: {transaction.paymentMethod}</p>}
						</div>
					</div>
					<div>
						<p
							className={`text-lg ${
								transaction.type === 'Deposit' ||
								transaction.type === 'Card To Card Receive' ||
								(transaction.type === 'Purchase' && transaction.status === 'reversal') ||
								transaction.type === 'Incoming Wire'
									? 'text-green'
									: 'text-red'
							}`}
						>
							{transaction.currencySymbol}
							{numeral(transaction.amount).format('0,0.00')}
						</p>
					</div>
				</div>
				<div className="text-sm flex justify-between mt-2">
					<p>{transaction.createdAt}</p>
					<h1 className="text-gradient text-end">{transaction.kind}</h1>
				</div>
			</div>
		</div>
	);
}
