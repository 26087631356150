import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';

export const AccountBalance = ({ userWallets }) => {
	const navigate = useNavigate();

	return (
		<div className="flex w-full">
			{userWallets
				.filter((account) => account.currency !== 'GBP')
				.map((account) => (
					<div
						onClick={() =>
							navigate('/transactions/paytora', { state: { currency: account.currency, balance: numeral(account.balance).format('0,0.00') } })
						}
						className={`${account.currency === 'USD' ? 'bg-blue' : 'bg-purple'} p-5 mt-[10px] rounded-[10px] w-full text-white ${
							account.currency === 'USD' ? 'me-[10px]' : ''
						}`}
					>
						<h1 className="font-bold text-md">Paytora {account.symbol} Account</h1>
						<h2 className="text-lg">{account.symbol + numeral(account.balance).format('0,0.00')}</h2>
						<p className="text-sm">Balance</p>
					</div>
				))}
		</div>
	);
};
