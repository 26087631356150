import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NotFound404 from '../../utils/404';

import Loader from '../../utils/loader';
import { TranferSchema } from '../../../utils/validations/user-schema';

import useValidation from '../../../hooks/useValidation';

import { getFeeByType } from '../../../services/fees';
import { transferToUserAPI } from '../../../services/transactions';

import { Header } from '../../../components/navigation/header';
import SelectInput from '../../../components/inputs/select-input';
import { Input } from '../../../components/inputs/text-input';
import { AccountDetailsSubHeader } from '../../../components/navigation/account_details_sub_header';
import { ErrorBubble } from '../../../components/utils/errorBubble';
import { PrimaryButton } from '../../../components/buttons/primary-button';
import { PageLogo } from '../../../components/utils/pageLogo';

export default function PaytoraMemberTransfer() {
	const navigate = useNavigate();

	const { userWallets } = useSelector((state) => state.accounts);

	const { currencyCode } = useParams();

	const [currency, setCurrency] = useState(userWallets[0].currency);

	const [amount, setAmount] = useState();
	const [customerNumber, setCustomerNumber] = useState('');
	const [customerName, setCustomerName] = useState('');
	const [reason, setReason] = useState('');
	const [errorMsg, setErrorMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [totalFee, setTotalFee] = useState(0);

	const { validationErrMsg, validate } = useValidation(TranferSchema);

	useEffect(() => {
		const fetchData = async () => {
			const feesResponse = await getFeeByType({ type: 'transfer' });
			console.log(feesResponse, 'the fees responses');
			if (feesResponse.isSuccess) feesResponse.data.forEach((fee) => setTotalFee(fee.cost));
		};

		fetchData();
	}, []);

	const transfer = async () => {
		const data = {
			customerNumber,
			customerName,
			currency: currencyCode ?? currency,
			amount: parseFloat(amount),
			reason,
		};

		if (!amount || amount < 0) return setErrorMsg('Please enter a valid amount');

		const isValid = validate(data);
		if (!isValid) return;

		setErrorMsg('');

		if (!isLoading) {
			setIsLoading(true);

			const response = await transferToUserAPI(data);

			if (!response.isSuccess) {
				setIsLoading(false);
				setErrorMsg(response.error ?? 'Please make sure you have enough balance & wallet member id is correct!');
				setReason('');
				return;
			}

			setIsLoading(false);
			navigate('/success');
		}
	};

	if (currency && currency !== 'USD' && currency != 'EUR') return <NotFound404 />;

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5">
			<div className="h-screen flex flex-col justify-between">
				<div>
					<Header title="Send Money" />
					<AccountDetailsSubHeader showBalance={false} showAmount={true} amount={amount > 0 ? amount - totalFee : 0} />

					<h1 className="font-bolder text-lg mb-[10px]">Paytora Member Transfer</h1>
					<div>
						<label className="text-darkGray">Select Currency</label>
						<SelectInput
							defaultValue={{ value: currencyCode ?? userWallets[0].currency, label: currencyCode ?? userWallets[0].currency }}
							onChange={(value) => {
								if (!currencyCode) setCurrency(value);
							}}
							options={
								currencyCode
									? []
									: userWallets.filter((wallet) => wallet.currency !== 'GBP').map((wallet) => ({ value: wallet.currency, label: wallet.currency }))
							}
						/>

						<p className="text-darkGray mb-2">
							<b>Your available amount is: </b>
							{(() => {
								const wallet = userWallets.find((wallet) => wallet.currency === (currencyCode ?? currency));
								return wallet ? `${wallet.balance}${wallet.symbol}` : '0.00';
							})()}
						</p>

						<label className="text-darkGray">Transfer Amount</label>

						<Input
							placeholder="Transfer Amount"
							type="number"
							value={amount}
							onChange={(value) => {
								if (value.length > 10) return;
								setAmount(value);
							}}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<label className="text-darkGray">Paytora Account Number</label>
						<Input
							onChange={(value) => {
								setCustomerNumber(value);
							}}
							type="text"
							value={customerNumber}
						/>
						<label className="text-darkGray">Paytora Account Full Name (ex. John Doe)</label>
						<Input
							onChange={(value) => {
								setCustomerName(value);
							}}
							type="text"
							value={customerName}
						/>
						<label className="text-darkGray">Transfer Reason</label>
						<SelectInput
							onChange={setReason}
							options={[
								{ label: 'Family Support', value: 'Family Support' },
								{ label: 'Gift', value: 'Gift' },
								{ label: 'Loan', value: 'Loan' },
							]}
							defaultValue={{ label: 'Select A Reason', value: '' }}
						/>
					</div>
					{totalFee > 0 && amount > 0 && <p className="text-red">{`Fees: €${totalFee} `}</p>}
					<div className="flex justify-center">
						<PageLogo />
					</div>
				</div>

				<div>
					{errorMsg && <ErrorBubble error={errorMsg} />}
					{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
					<PrimaryButton iconPath="/icons/arrow_out_white.svg" text="Send" textColor="text-white" onClick={transfer} />
				</div>
			</div>
		</div>
	);
}
