import { z } from 'zod';

export const TranferSchema = z.object({
	currency: z.string().trim().min(3, { message: 'Please select currency!' }),
	amount: z.number().min(1, { message: 'Please enter a valid amount' }),
	customerNumber: z.string().trim().min(1, { message: 'Account number is required!' }),
	customerName: z.string().trim().min(1, { message: 'Customer Name is Required!' }),
	reason: z.string().trim().min(1, { message: 'Please add a reason to transfer!' }),
});

export const SwapSchema = z
	.object({
		fromCurrency: z.string().trim().min(3, { message: 'Please select from currency!' }),
		amount: z.number().min(1, { message: 'Please enter a valid amount' }),
		toCurrency: z.string().trim().min(3, { message: 'Please select to currency!' }),
	})
	.refine((data) => data.fromCurrency !== data.toCurrency, {
		message: 'From currency and exchanged currency must be different!',
		path: ['toCurrency'],
	});

export const bankWithdrawSchema = z.object({
	bankName: z.string().trim().min(1, { message: 'Please select bank name!' }),
	bankAddress: z.string().trim().min(1, { message: 'Please select bank address!' }),
	bankCity: z.string().trim().min(1, { message: 'Please select bank city!' }),
	bankZipCode: z.string().trim().min(1, { message: 'Please select bank zipcode!' }),
	bankCountry: z.string().trim().min(1, { message: 'Please select bank country!' }),
	customerName: z.string().trim().min(1, { message: 'Please select account holder name!' }),
	swift: z.string().trim().min(1, { message: 'Please select bank swift/bic!' }),
	bankIBAN: z.string().trim().min(1, { message: 'Please select bank IBAN/Route!' }),
	amount: z.number().min(1, { message: 'Please enter a valid amount' }),
});

export const physicalCardApplySchema = z.object({
	city: z.string().trim().min(1, { message: 'Please enter a city!' }),
	street: z.string().trim().min(1, { message: 'Please enter a street!' }),
	number: z.string().trim().min(1, { message: 'Please select apartment/house Number!' }),
	postCode: z.string().trim().min(1, { message: 'Please select a postal code!' }),
	country: z.string().trim().min(1, { message: 'Please select a country!' }),
	method: z.string().trim().min(1, { message: 'Please select a shipping method!' }),
});
