import { useLocation, useNavigate } from 'react-router-dom';

import NotFound404 from '../../utils/404';

import { Header } from '../../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../../components/navigation/account_details_sub_header';
import { GradientButton } from '../../../components/buttons/gradient_button';
import { PageLogo } from '../../../components/utils/pageLogo';

export default function SendPage() {
	const navigate = useNavigate();

	const location = useLocation();
	const { currency, isIban } = location.state || {};

	const hasIban = JSON.parse(localStorage.getItem('hasIban'));

	if (currency && currency !== 'USD' && currency != 'EUR') return <NotFound404 />;

	return (
		<div className="flex flex-col justify-between p-5 h-screen">
			<div>
				<Header title="Send Money" />
				<AccountDetailsSubHeader />
				<p className="text-lg font-bolder mb-[10px]">Send Funds</p>
				{!isIban && <GradientButton text="To Paytora Member" onClick={() => navigate(`/send/paytora-member/${currency ?? ''}`)} />}
				{!currency && hasIban === 'true' && (
					<GradientButton text="From My IBAN Account" onClick={() => navigate('/send/withdrawal', { state: { type: 'iban' } })} />
				)}
				{!isIban && <GradientButton text="From My Paytora Account" onClick={() => navigate('/send/withdrawal', { state: { type: 'paytora' } })} />}
			</div>
			<PageLogo className="mb-20" />
		</div>
	);
}
