import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Loader from '../../utils/loader';
import { getVerestroIbanAPI } from '../../../services/ibans';

import { Header } from '../../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../../components/navigation/account_details_sub_header';
import { InstructionBubble } from '../../../components/utils/InstructionBubble';

export default function WireTransfer() {
	const location = useLocation();
	const { isCard = false, cardId } = location.state || {}; // type can be IBAN or CARD

	const [isLoading, setIsLoading] = useState(false);

	const [ibanData, setIbanData] = useState(null);

	const getVerestroIBANDetails = async () => {
		// add error when not agreed
		if (!isLoading) {
			setIsLoading(true);
			const response = await getVerestroIbanAPI(cardId);
			if (!response.isSuccess) return setIsLoading(false);

			setIbanData(response.data);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getVerestroIBANDetails();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between p-5 h-screen">
			{ibanData ? (
				<div>
					<Header title={isCard ? 'Card IBAN Deposit' : 'IBAN Deposit'} />
					<AccountDetailsSubHeader />

					<InstructionBubble
						title="Please be advised"
						text={
							<div>
								<h1 className="text-md text-center font-bold mb-1">You can receive funds to your iban account by the following options</h1>
								<ul className="text-start">
									<li>- Your employer (must be from coporate/companies)</li>
									<li>- Account owned by you</li>
									<li>- Cash (by any money transfer institute (exchange))</li>
								</ul>
							</div>
						}
					/>
					<div>
						<h1 className="text-lg font-bolder mt-[40px]">Your {isCard && 'Card'} IBAN account details</h1>
						<p className="text-sm text-darkGray mt-[20px]">Account holder name</p>
						<p className="mb-[20px]">{ibanData ? ibanData.fullname : ''}</p>
						<p className="text-sm text-darkGray">IBAN</p>
						<p className="mb-[20px]">{ibanData ? ibanData.iban : ''}</p>
						<p className="text-sm text-darkGray">BIC/SWIFT</p>
						<p className="mb-[20px]">{ibanData ? ibanData.bic : ''}</p>
						<p className="text-sm text-darkGray">Bank Name</p>
						<p className="mb-[20px]">Bank Polska Kasa Opieki Spolka Akcyjna</p>
						<p className="text-sm text-darkGray">Address</p>
						<p className="mb-[20px]">al. Wojska Polskiego 1</p>
						<p className="text-sm text-darkGray">City</p>
						<p className="mb-[20px]">Szczecin</p>
						<p className="text-sm text-darkGray">Zip</p>
						<p className="mb-[20px]">70-470</p>
						<p className="text-sm text-darkGray">Country</p>
						<p className="mb-[20px]">Poland (PL)</p>
					</div>
				</div>
			) : (
				<div className="p-5">
					<Header title={isCard ? 'Card IBAN Deposit' : 'IBAN Deposit'} />

					<h1 className="text-xl text-center font-bold">We are still processing your IBAN or you didn't applied to one, please try again later.</h1>
				</div>
			)}
		</div>
	);
}
