import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';

export const CardsBalance = ({ cardsBalance }) => {
	const navigate = useNavigate();

	return (
		<div onClick={() => navigate('/cards')}>
			<div className="bg-lightBlack p-[14px] h-[160px] mt-[10px] rounded-[10px]">
				<div className="border border-gold h-full rounded-[6px] p-3">
					<div className="flex justify-between">
						<div className="text-gold">
							<img src="/assets/card/card_logo_gold.svg" />
							<p className="font-bold text-xxl mt-[20px]">{'€' + numeral(cardsBalance).format('0,0.00')}</p>
							<p className="text-sm">Your cards total available balance</p>
						</div>
						<div className="flex flex-col items-center">
							<img src="/assets/card/card_apple.svg" className="mb-[5px]" />
							<img src="/assets/card/card_google.svg" />
							<img src="/assets/card/card_mastercard.svg" />
						</div>
					</div>
				</div>
			</div>
		</div>
		// <div
		// 	className="flex justify-between bg-black p-5 mt-[10px] rounded-[10px] w-full text-gold"
		// 	// onClick={() => navigate(`/transactions/cards?balance=${numeral(cardsBalance).format('0,0.00')}`)}
		// 	onClick={() => navigate('/cards')}
		// >
		// 	<div>
		// 		<p className="text-sm">Your cards total available balance</p>
		// 		<h1 className="font-bold text-lg">{'€' + numeral(cardsBalance).format('0,0.00')}</h1>
		// 	</div>
		// 	<img src="/assets/icons/right_arrow.svg" className="text-white" />
		// </div>
	);
};
