import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Median from 'median-js-bridge';

import { medianBioLogin } from './utils/auth';

import { PrimaryButton } from '../../components/buttons/primary-button';
import { SecondaryButton } from '../../components/buttons/secondary-button';

export default function WelcomePage() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const [isNative, setIsNative] = useState(false);

	const [hasTouchId, setHasTouchId] = useState(false); // updated to false

	const [hasSecert, setHasSecret] = useState(false); // update to false

	const medianCheckBio = async () => {
		await Median.auth.status().then((data) => {
			if (isNative && data) {
				setHasTouchId(data.hasTouchId);
				setHasSecret(data.hasSecret);
			}
		});
	};

	useEffect(() => {
		setIsNative(Median.isNativeApp());
		medianCheckBio();
	});

	return (
		<div
			className="bg-cover bg-center bg-no-repeat min-h-screen relative flex flex-col justify-between"
			style={{ backgroundImage: "url('assets/welcome/homeBG.svg" }}
		>
			<div className="flex flex-col items-center justify-center text-white">
				<img src="/assets/welcome/homeCards.svg" alt="homeCards" className="mb-[10px]" />
				<h1 className="text-2xl font-bolder  mb-[20px]">Welcome To Paytora</h1>
				<p className="text-lg mb-[20px] w-[75%] text-center mb-[46px]">Open a personal account within just 5 minutes</p>
			</div>
			<div className="flex flex-col pb-5 pe-5 ps-5">
				<PrimaryButton text={t('login.loginButton')} className="bg-white mb-[10px]" textColor="text-black" onClick={() => navigate('/login')} />

				{hasTouchId && hasSecert && (
					<PrimaryButton
						text={t('login.bioLoginButton')}
						className="bg-white"
						textColor="text-black"
						onClick={async () => {
							const response = await medianBioLogin();
							if (!response.isSuccess) {
								navigate('/login');
								return;
							}
							navigate('/success');
						}}
						icon={<img src="/assets/icons/bio_finger.svg" alt="homeIcon" className="ms-2" />}
					/>
				)}

				<SecondaryButton text={t('login.newUserButton')} className="mt-[10px]" onClick={() => navigate('/register')} />
			</div>
			<div className="flex justify-center pb-5">
				<img src="/assets/welcome/homeIcon.svg" alt="homeIcon" className="" />
			</div>
		</div>
	);
}
