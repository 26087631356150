import { useState } from 'react';

import useValidation from '../../hooks/useValidation';

import { updateUserPassword } from '../../services/user';

import { PrimaryButton } from '../../components/buttons/primary-button';

import { Header } from '../../components/navigation/header';

import { ErrorBubble } from '../../components/utils/errorBubble';
import PasswordInput from '../../components/inputs/password-input';
import { InstructionBubble } from '../../components/utils/InstructionBubble';

import { ChangePasswordSchema } from '../../utils/validations/user/user_schema';

export default function ChangePassword() {
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');

	const { validationErrMsg, validate } = useValidation(ChangePasswordSchema);

	const [apiErrMsg, setApiErrMsg] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const updatePassword = async (e) => {
		e.preventDefault();

		const data = {
			currentPassword,
			newPassword,
			confirmNewPassword,
		};

		const isValid = validate(data);
		if (!isValid) return;

		if (!isLoading) {
			setIsLoading(true);
			const response = await updateUserPassword({ oldPassword: currentPassword, newPassword });
			if (!response.isSuccess) {
				setIsLoading(false);
				return setApiErrMsg('Please make sure you enter your correct current password.');
			}

			localStorage.clear();
			window.location.reload();
		}
		setIsLoading(false);
	};

	return (
		<form onSubmit={updatePassword} className="flex flex-col justify-between p-5 h-screen">
			<div>
				<Header title="Update Password" />
				<InstructionBubble
					title="Instructions to update a password"
					text="Your password must be between 8 and 12 characters that must contain at least one capital letter one lowercase letter, numbers and a
						special character as shown [# $ ! @ * & %]"
					className="mt-[40px] mb-[20px]"
				/>
				<p className="font-bold mt-2 mb-1">Current password</p>

				<PasswordInput value={currentPassword} onChange={setCurrentPassword} placeholder="Enter Current Password" />
				<p className="font-bold mt-2 mb-1">Set new password</p>
				<PasswordInput value={newPassword} onChange={setNewPassword} placeholder="Enter New Password" />
				<PasswordInput value={confirmNewPassword} onChange={setConfirmNewPassword} placeholder="Re-Enter New Password" />
			</div>
			<div>
				{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
				{apiErrMsg && <ErrorBubble error={apiErrMsg} />}
				<PrimaryButton text="Update Password" textColor="text-white" />
			</div>
		</form>
	);
}
