import { useEffect, useState } from 'react';

import ReactSelect from 'react-select';

import { Header } from '../../components/navigation/header';

import Loader from '../utils/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import NotFound404 from '../utils/404';
import { Input } from '../../components/inputs/text-input';
import { PrimaryButton } from '../../components/buttons/primary-button';
import SelectInput from '../../components/inputs/select-input';
import { CountriesListWithPrefix } from '../../utils/constants/countries-list';
import { PageLogo } from '../../components/utils/pageLogo';
import { getUserProfileAPI, updateUserAddressAPI } from '../../services/user';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { CustomModal } from '../../components/modals/custom_modal';

const customStyles = {
	control: (provided) => ({
		...provided,
		backgroundColor: '#0000000D', // Background color
		borderRadius: '10px', // Border radius
		border: '0px solid black', // Border color
	}),
	// Customize other elements as needed
	indicatorSeparator: () => ({
		display: 'none', // Hide the indicator separator
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: 'black', // Dropdown arrow color
	}),
	input: (provided) => ({
		...provided,
		color: 'black', // Input text color
	}),
	placeholder: (provided) => ({
		...provided,
		color: 'black',
	}),
	valueContainer: (provided) => ({
		...provided,
		height: '50px',
		display: 'flex',
		alignItems: 'center', // Center text vertically
	}),
	// Add more custom styles as needed
};

export default function EditProfileAddress() {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const [addressData, setAddressData] = useState(null);
	const [errMsg, setErrMsg] = useState('');

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const getUserProfile = async () => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await getUserProfileAPI();
			if (response.isSuccess) {
				setAddressData(response.data);
			} else setErrMsg('Unable to load your profile, please try again later');
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getUserProfile();
	}, []);

	const updateAddress = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await updateUserAddressAPI(addressData);
			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error);
			}
		}
		setIsLoading(false);
		// navigate('/success');
	};

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5 flex flex-col justify-between h-screen">
			{addressData ? (
				<div>
					<Header title="Edit Address" />
					<h1 className="text-xl font-bolder mt-[30px] mb-[10px]">Address Information</h1>
					<p className="text-darkGray">Please fill up all the correct information below</p>
					<p className="text-sm mt-[25px] text-end">All the fields are required*</p>

					<p className="text-darkGray">Street</p>
					<Input
						onChange={(value) =>
							setAddressData((data) => ({
								...data,
								street: value,
							}))
						}
						placeholder=""
						type="text"
						value={addressData.street}
					/>
					<p className="text-darkGray">City</p>
					<Input
						onChange={(value) =>
							setAddressData((data) => ({
								...data,
								city: value,
							}))
						}
						placeholder=""
						type="text"
						value={addressData.city}
					/>
					<p className="text-darkGray">Apartment</p>
					<Input
						onChange={(value) =>
							setAddressData((data) => ({
								...data,
								apartment: value,
							}))
						}
						placeholder=""
						type="text"
						value={addressData.apartment}
					/>
					<p className="text-darkGray">Postal Code</p>
					<Input
						onChange={(value) =>
							setAddressData((data) => ({
								...data,
								postalCode: value,
							}))
						}
						placeholder=""
						type="text"
						value={addressData.postalCode}
					/>
					<p className="text-darkGray">Country</p>
					<SelectInput
						defaultValue={{
							label: CountriesListWithPrefix.find((country) => country.code === addressData.country).country,
							value: CountriesListWithPrefix.find((country) => country.code === addressData.country).code,
						}}
						onChange={(value) => {
							setAddressData((data) => ({
								...data,
								country: value,
							}));
						}}
						options={CountriesListWithPrefix.map((opt) => ({
							value: opt.code,
							label: opt.country,
						}))}
						placeHolder="Country"
					/>
				</div>
			) : (
				<div></div>
			)}
			<div className="flex flex-col justify-center">
				<PageLogo />
				{errMsg && <ErrorBubble error={errMsg} />}
				<PrimaryButton text="Update Address" onClick={updateAddress} />
			</div>

			<CustomModal
				isOpen={isSuccessModalOpen}
				title="Address Updated"
				subTitle="Your KYC address updated successfully"
				children={
					<div>
						<p className="text-md text-center mb-5">If you have updated your address to apply for an IBAN, please try submitting your IBAN again</p>
						<PrimaryButton
							text="Close"
							onClick={() => {
								setIsSuccessModalOpen(false);
								navigate('/');
							}}
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
}
