import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import numeral from 'numeral';

import { getDecodedToken } from '../../utils/functions/token';

import { BackButton } from '../buttons/back-button';
import { PrimaryButton } from '../buttons/primary-button';

import { DropdownUserMenu } from './user-menu';
import { logout } from '../../services/auth';
import LanguageSwitcher from '../utils/languageSwitcher';

export const HeaderOld = ({
	showUser = true,
	showHome = true,
	showLogout = false,
	showLogo = true,
	showBack = true,
	showBalance = true,
	background = 'subBrand',
	backbuttonNavigation,
	showLanguage = false,
}) => {
	const navigate = useNavigate();
	const [walletId, setWalletId] = useState('');
	const [mainBalance, setMainBalance] = useState(0);

	useEffect(() => {
		const decodedToken = getDecodedToken();

		setWalletId(decodedToken?.customerNumber ?? '');
		setMainBalance(localStorage.getItem('totalBalance') ?? 0);
	}, []);

	return (
		<div>
			<div className={`bg-${background} border-b p-2 border `}>
				<div className={`p-3 flex ${showLogo ? 'justify-between' : 'justify-end'}  items-center`}>
					{showLogo && (
						<a href="/" className="lg:w-1/4 md:w-1/3 sm:w-1/3 xsm:w-[70%] flex justify-start me-2">
							<img src="/assets/logo.png" alt="logo" className="" />
						</a>
					)}

					<div className="flex flex-col text-center">{showUser && <DropdownUserMenu />}</div>
					{showLogout && <PrimaryButton onClick={() => logout()} text="Logout" className="p-5" />}
				</div>
				{showLanguage && (
					<div className="flex justify-end">
						<LanguageSwitcher />
					</div>
				)}
				{showHome && (
					<div className="flex justify-center">
						<button className="bg-primary p-5 text-white" onClick={() => navigate('/')}>
							Home Page
						</button>
					</div>
				)}
			</div>
			{showUser && <div className="text-primary font-bold text-center mt-1">PAYTORA ACCOUNT NUMBER - {walletId}</div>}
			{showBalance && (
				<div className="text-center font-bold p-5">
					<h1 className="text-xl">All Your Balances in EUR value: €{numeral(mainBalance).format('0,0.00')}</h1>
				</div>
			)}
			{showBack && (
				<div className="flex justify-end me-10">
					<BackButton navigation={backbuttonNavigation} />
				</div>
			)}
		</div>
	);
};
