import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import numeral from 'numeral';

import NotFound404 from '../utils/404';

import { Header } from '../../components/navigation/header';
import { GradientButton } from '../../components/buttons/gradient_button';
import { TransactionsContainer } from '../../components/transactions/transactionsContainer';
import { BottomMenu } from '../../components/navigation/bottom_menu';

import { getUserTransactionsAPI } from '../../services/user';

export default function PayToraTransactions() {
	const navigate = useNavigate();

	const location = useLocation();
	const { currency, balance } = location.state || {};

	const [transactions, setTransactions] = useState([]);

	const currencySymbol = currency === 'USD' ? '$' : '€';

	const fetchTransactions = async () => {
		const transactions = await getUserTransactionsAPI('paytora', null, currency);

		if (transactions.isSuccess) setTransactions(transactions.data);
	};

	useEffect(() => {
		fetchTransactions();
	}, []);

	if (!currency) return <NotFound404 />;

	return (
		<div className="flex flex-col justify-between bg-lightGreen p-5 h-screen">
			<div>
				<Header title={`Paytora ${currency} Account Transactions`} />

				<div className={`${currency === 'USD' ? 'bg-blue' : 'bg-purple'} p-5 mt-[10px] mb-[20px] rounded-[10px] w-full text-white`}>
					<div className="flex justify-between">
						<h1 className="font-bold text-md">Paytora {currencySymbol} Account</h1>
						<div>
							<h2 className="text-lg">{currencySymbol + numeral(balance).format('0,0.00')}</h2>
							<p className="text-sm text-end">Balance</p>
						</div>
					</div>
				</div>

				<h1 className="font-bold text-lg mb-[10px]">Make an action</h1>
				<div className="flex space-x-2 text-lg">
					<GradientButton text="Send" onClick={() => navigate('/send', { state: { currency } })} iconPath="icons/arrow_out.svg" />
					<GradientButton text="Deposit" onClick={() => navigate('/deposit', { state: { currency } })} iconPath="icons/arrow_in.svg" />
				</div>

				<TransactionsContainer transactions={transactions} />
			</div>

			<BottomMenu selected="" />
		</div>
	);
}
