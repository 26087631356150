import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import numeral from 'numeral';

import { Input } from '../inputs/text-input';
import SelectInput from '../inputs/select-input';

import { PrimaryButton } from '../buttons/primary-button';
import { GradientButton } from '../buttons/gradient_button';
import { SecondaryButton } from '../buttons/secondary-button';

import { CustomModal } from '../modals/custom_modal';

import { ErrorBubble } from '../utils/errorBubble';
import { CountriesList } from '../../utils/constants/countries-list';
import { physicalCardApplySchema } from '../../utils/validations/user-schema';

import Loader from '../../pages/utils/loader';

import { cardApplyAPI } from '../../services/cards';
import { getFeeByType } from '../../services/fees';

import useValidation from '../../hooks/useValidation';

export const TemplateCardItem = ({ template }) => {
	const { t } = useTranslation();

	const { validationErrMsg, validate } = useValidation(physicalCardApplySchema);

	const [kind, setKind] = useState(template.kind);

	const [shippingFee, setShippingFee] = useState(0);

	const [isVirtualApplyModalOpen, setIsVirtualAppyModalOpen] = useState(false);
	const [isPhysicalApplyModalOpen, setIsPhysicalApplyModalOpen] = useState(false);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [selectedFromMethod, setSelectedFromMethod] = useState('paytora'); // paytora or iban

	const [errMsg, setErrMsg] = useState('');

	const [conditions, setConditions] = useState({
		agreeToTerms: false,
		agreeToPrivacyPolicy: false,
	});

	const shippingMethodOptions = [
		{ value: 'basic', label: 'Basic (30 days)' },
		{ value: 'standard', label: 'Standard (15 days)' },
		{ value: 'fast', label: 'Fast (5-7 days)' },
	];

	const [shippingData, setShippingData] = useState({
		country: CountriesList[0],
		city: '',
		street: '',
		number: '',
		postCode: '',
		method: shippingMethodOptions[0].value,
		// pin: '',
	});

	const handleCheckboxChange = (fieldName) => {
		setConditions((data) => ({ ...data, [fieldName]: !data[fieldName] }));
	};

	const [cardDesign, setCardDesign] = useState({
		bgColor: 'bg-lightBlack',
		borderColor: 'border-gold',
		balanceTextColor: 'text-white',
		limitTextColor: 'text-darkGold',
		cardDetailsTextColor: 'text-white',
		logoPath: 'card_logo_gold.svg',
	});

	const getCardDesignByKind = () => {
		if (kind === 'premium') {
			setCardDesign((prevCardDesign) => ({
				...prevCardDesign,
				bgColor: 'bg-brown',
				borderColor: 'border-darkBrown',
				limitTextColor: 'text-white',
				logoPath: 'card_logo_white.svg',
			}));
		}
		if (kind === 'kids_blue') {
			setCardDesign((prevCardDesign) => ({
				...prevCardDesign,
				bgColor: 'bg-darkBlue',
				borderColor: 'border-white',
				limitTextColor: 'text-white',
				logoPath: 'card_logo_white.svg',
			}));
		}

		if (kind === 'kids_silver') {
			setCardDesign((prevCardDesign) => ({
				...prevCardDesign,
				bgColor: 'bg-silver',
				borderColor: 'border-white',
				limitTextColor: 'text-white',
				logoPath: 'card_logo_white.svg',
			}));
		}
	};

	const apply = async () => {
		if (!conditions.agreeToPrivacyPolicy || !conditions.agreeToTerms) return setErrMsg('Please accept the terms & conditions');

		const isApplyingCard = localStorage.getItem('applyCard');

		let shipping = null;
		if (template.type === 'physical') {
			const isValid = validate(shippingData);
			if (!isValid) return setErrMsg('');
			shipping = shippingData;
		}

		if (isApplyingCard === 'true') return window.location.reload();

		localStorage.setItem('applyCard', true);

		if (!isLoading) {
			setIsLoading(true);
			const response = await cardApplyAPI(template._id, { shippingData: shipping, paymentMethod: selectedFromMethod });

			localStorage.setItem('applyCard', false);

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error);
			}

			template.type === 'virtual' && setIsVirtualAppyModalOpen(false);
			template.type === 'physical' && setIsPhysicalApplyModalOpen(false);

			setIsLoading(false);
			setIsSuccessModalOpen(true);
		}
	};

	const getShippingFees = async () => {
		const feesResponse = await getFeeByType({ type: 'shipping' });
		console.log(feesResponse, ' the fees response');
		if (feesResponse.isSuccess) setShippingFee(feesResponse.data[0].shippingCost[shippingData.method]);
	};

	useEffect(() => {
		getCardDesignByKind();
		if (template.type === 'physical') getShippingFees();
		if (template.kind === 'kids_blue' || template.kind === 'kids_silver') setKind('kids');
	}, [shippingData.method]);

	return (
		<div>
			<div className="flex justify-between">
				<p className="text-md mb-[10px] mt-[10px]">Card type - {kind.charAt(0).toUpperCase() + kind.slice(1)} </p>
			</div>
			<div className={`${cardDesign.bgColor} p-2`}>
				<div className={`border ${cardDesign.borderColor} h-full p-5`}>
					<div className="h-full">
						<div className="flex items-start justify-between">
							<div className={`${cardDesign.limitTextColor}`}>
								<p>Card Limit</p>
								<p className="text-xxl font-bolder">{template.currency.symbol + numeral(template.limit).format('0,0')} </p>
							</div>
							<img src={`/assets/card/${cardDesign.logoPath}`} />
						</div>
						<div className="flex items-end justify-between">
							<div className={`${cardDesign.cardDetailsTextColor} text-md mt-[25px]`}>
								<p className="text-lg font-bolder">**** **** **** ****</p>
								<p>Exp **/**</p>
							</div>
							<div className="flex flex-col justify-between">
								<img src="/assets/card/card_apple.svg" className="mb-[5px]" />
								<img src="/assets/card/card_google.svg" />
								<img src="/assets/card/card_mastercard.svg" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<GradientButton
				text="Apply"
				className="mt-2 !text-center"
				onClick={() => (template.type === 'virtual' ? setIsVirtualAppyModalOpen(true) : setIsPhysicalApplyModalOpen(true))}
			/>

			{/** MOVE TO STANDALONE MODAL */}
			<CustomModal
				isOpen={isVirtualApplyModalOpen}
				onClose={() => setIsVirtualAppyModalOpen(false)}
				title="Apply for a new virtual card"
				subTitle={`You will be charged for €${template.cost}`}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							<p className="text-lg font-bold mt-5">How would you like to pay?</p>
							<SelectInput
								options={['paytora', 'iban'].map((method) => ({
									value: method,
									label: method.toUpperCase(),
								}))}
								defaultValue={{ value: selectedFromMethod, label: selectedFromMethod.toUpperCase() }}
								onChange={setSelectedFromMethod}
							/>
							<div className="mt-5">
								<input
									type="checkbox"
									id="termsCheckbox"
									checked={conditions.agreeToTerms}
									onChange={() => handleCheckboxChange('agreeToTerms')}
									className="me-2"
								/>
								<label htmlFor="termsCheckbox" className="ml-2">
									{t('termsAndConditions.title')}
									<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/card/terms', '_blank')}>
										{t('termsAndConditions.terms')}
									</span>
								</label>
							</div>
							<div className="mb-[20px]">
								<input
									type="checkbox"
									id="privacyCheckbox"
									checked={conditions.agreeToPrivacyPolicy}
									onChange={() => handleCheckboxChange('agreeToPrivacyPolicy')}
									className="me-2"
								/>
								<label htmlFor="privacyCheckbox" className="ml-2">
									{t('termsAndConditions.title')}
									<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/card/privacy', '_blank')}>
										{t('termsAndConditions.privacy')}
									</span>
								</label>
							</div>

							{errMsg && <ErrorBubble error={errMsg} />}
							<div className="flex flex-col items-center mt-[10px]">
								<PrimaryButton text="Apply" textColor="text-white" onClick={apply} />
								<SecondaryButton text="Cancel" onClick={() => setIsVirtualAppyModalOpen(false)} textColor="text-black" />
							</div>
						</div>
					)
				}
			/>

			{/** MOVE TO STANDALONE MODAL */}
			<CustomModal
				isOpen={isPhysicalApplyModalOpen}
				onClose={() => setIsPhysicalApplyModalOpen(false)}
				title="Apply for a new physical card"
				subTitle={`You will be charged for €${template.cost}`}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							<p className="mt-2 mb-2">Where you want the card to be delivered</p>
							<div>
								<div className="inputs-container">
									<SelectInput
										defaultValue={{ value: CountriesList[0], label: CountriesList[0] }}
										onChange={(value) => {
											setShippingData((data) => ({
												...data,
												country: value,
											}));
										}}
										options={CountriesList.map((opt) => ({
											value: opt,
											label: opt,
										}))}
										placeHolder="Country"
									/>
									<Input
										onChange={(value) => {
											setShippingData((data) => ({ ...data, city: value }));
										}}
										placeholder="City"
										type="text"
										value={shippingData.city}
									/>
									<Input
										onChange={(value) => {
											setShippingData((data) => ({ ...data, street: value }));
										}}
										placeholder="Street"
										type="text"
										value={shippingData.street}
									/>
									<Input
										onChange={(value) => {
											setShippingData((data) => ({ ...data, number: value }));
										}}
										placeholder="Apartment/House Number"
										type="text"
										value={shippingData.number}
									/>
									<Input
										onChange={(value) => {
											setShippingData((data) => ({ ...data, postCode: value }));
										}}
										placeholder="ZipCode"
										type="text"
										value={shippingData.postCode}
									/>

									<SelectInput
										defaultValue={shippingMethodOptions[0]}
										onChange={(value) => {
											setShippingData((data) => ({
												...data,
												method: value,
											}));
										}}
										options={shippingMethodOptions.map((opt) => ({
											value: opt.value,
											label: opt.label,
										}))}
										placeHolder="Shipping Method"
									/>

									<p className="text-lg font-bold mt-5">How would you like to pay?</p>
									<SelectInput
										options={['paytora', 'iban'].map((method) => ({
											value: method,
											label: method.toUpperCase(),
										}))}
										defaultValue={{ value: selectedFromMethod, label: selectedFromMethod.toUpperCase() }}
										// defaultValue={{ value: CountriesList[0], label: selectedFromMethodtoU }}
										onChange={setSelectedFromMethod}
									/>

									{/* <Input
										onChange={(value) => {
											if (/^\d*$/.test(value) && value.length <= 4) {
												setFormData((data) => ({ ...data, pin: value }));
											}
										}}
										placeholder="Pin"
										type="text"
										value={formData.pin}
										className="bg-secondary border-2 border-black inline-block text-black placeholder:text-black"
									/>
									<p className="text-center mb-5 text-primary">* Please select a pin that you remember to use it for future card usages!</p> */}
								</div>

								<div className="">
									<input
										type="checkbox"
										id="termsCheckbox"
										checked={conditions.agreeToTerms}
										onChange={() => handleCheckboxChange('agreeToTerms')}
										className="me-2"
									/>
									<label htmlFor="termsCheckbox" className="ml-2">
										{t('termsAndConditions.title')}
										<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/card/terms', '_blank')}>
											{t('termsAndConditions.terms')}
										</span>
									</label>
								</div>
								<div className="mb-[10px]">
									<input
										type="checkbox"
										id="privacyCheckbox"
										checked={conditions.agreeToPrivacyPolicy}
										onChange={() => handleCheckboxChange('agreeToPrivacyPolicy')}
										className="me-2"
									/>
									<label htmlFor="privacyCheckbox" className="ml-2">
										{t('termsAndConditions.title')}
										<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/card/privacy', '_blank')}>
											{t('termsAndConditions.privacy')}
										</span>
									</label>
								</div>

								{errMsg && <ErrorBubble error={errMsg} />}
								{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
								<div className="flex flex-col items-center mt-[10px]">
									<p className="text-red mt-[10px] mb-[10px]">You will be charged for additional €{shippingFee} for shipping</p>
									<PrimaryButton text="Apply" textColor="text-white" onClick={apply} />
									<SecondaryButton text="Cancel" onClick={() => setIsPhysicalApplyModalOpen(false)} textColor="text-black" />
								</div>
							</div>
						</div>
					)
				}
			/>

			<CustomModal
				isOpen={isSuccessModalOpen}
				onClose={() => setIsSuccessModalOpen(false)}
				title={'Your card has been issued!'}
				subTitle={`${
					template.type === 'virtual'
						? 'An email sent to you with the card details'
						: 'Your card will be send to your address within your choosen timeframe'
				}`}
				children={
					<div className="flex justify-center">
						<PrimaryButton
							onClick={() => {
								setIsSuccessModalOpen(false);
								window.location.reload();
							}}
							text="Close"
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
};
