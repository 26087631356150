import { z } from 'zod';
import { validationErrors } from '../../text/errors.text';

export const loginSchema = (t) =>
	z.object({
		email: z.string().email({ message: t('login.errors.invalidEmail') }),
		password: z
			.string()
			.trim()
			.min(1, { message: t('login.errors.requiredPassword') }),
	});

export const registerConfirmSchema = (t) =>
	z
		.object({
			otp: z
				.string()
				.trim()
				.min(4, { message: t('register.errors.invalidOTP') }),
			password: z
				.string()
				.trim()
				.min(1, { message: t('register.errors.requiredPassword') })
				.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,}$/, {
					message: t('register.passwordInstructions'),
				}),
			confirmPassword: z
				.string()
				.trim()
				.min(1, { message: t('register.errors.requiredConfirmPassword') }),
		})
		.refine((data) => data.password === data.confirmPassword, {
			message: t('register.errors.passwordsNotMatch'),
		});

export const registerSchema = (t) =>
	z.object({
		email: z.string().email({ message: t('register.errors.invalidEmail') }),
		type: z
			.string()
			.trim()
			.min(1, { message: t('register.errors.invalidType') }),
	});
