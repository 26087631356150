import CardAndIbanTransactionItem from './cardAndIbanTransactionItem';
import PaytoraTransactionItem from './paytoraTransactionItem';

export const TransactionsContainer = ({ transactions = [] }) => {
	return (
		<div className={`bg-white mt-[40px] rounded-[10px] h-full`}>
			<div className="flex justify-between items-center p-5">
				<h1 className="text-lg font-bolder">Activities</h1>
				{/* <p className="text-gradient text-sm">All Transactions</p> */}
			</div>
			{transactions.length === 0 ? (
				<h1 className="p-2 text-lg text-center">You don't have any transactions yet</h1>
			) : (
				<div>
					{transactions.map((transaction) => {
						return transaction.kind === 'Paytora' ? (
							<PaytoraTransactionItem transaction={transaction} />
						) : (
							<CardAndIbanTransactionItem transaction={transaction} />
						);
					})}
				</div>
			)}
		</div>
	);
};
