import numeral from 'numeral';

export default function PaytoraTransactionItem({ transaction }) {
	return (
		<div>
			{/** Transfer  */}
			<div className="p-5 mb-[10px]">
				<div className="flex justify-between mb-[10px]">
					<h1 className="text-lg text-gradient">{transaction.type}</h1>
					<p className="text-sm">
						Status - <span className={`${transaction.status === 'completed' ? 'text-green' : 'text-orange'}`}>{transaction.status}</span>
					</p>
				</div>
				<div className="flex justify-between">
					<div className="flex">
						<img src="/assets/transactions/transfer.svg" className="me-[10px]" />
						<div className="flex flex-col text-xsm justify-center">
							{transaction.receiver && !transaction.isReceiver && <p className="text-md">{transaction.receiver.fullName}</p>}
							{transaction.receiver && transaction.isReceiver && <p className="text-md">{transaction.creator}</p>}
							{transaction.description && <p>{transaction.description}</p>}
							{transaction.paymentMethod && <p>Method: {transaction.paymentMethod}</p>}
						</div>
					</div>
					<div>
						{transaction.type === 'Swap' ? (
							<div className="flex">
								<p className="text-lg text-red">
									{transaction.currencySymbol}
									{numeral(transaction.amount).format('0,0.00')}
								</p>
								<img src="/assets/icons/exchange_horizontal.svg" className="me-2 ms-2" />
								<p className="text-lg text-green">
									{transaction.exchangedCurrencySymbol}
									{numeral(transaction.exchangedAmount).format('0,0.00')}
								</p>
							</div>
						) : (
							<p
								className={`text-lg ${
									transaction.type === 'Deposit' || (transaction.receiver && transaction.isReceiver) ? 'text-green' : 'text-red'
								}`}
							>
								{transaction.currencySymbol}
								{numeral(transaction.amount).format('0,0.00')}
							</p>
						)}
					</div>
				</div>
				<div className="text-sm flex justify-between mt-2">
					<p>{transaction.createdAt}</p>
					<h1 className="text-gradient text-end">{transaction.kind}</h1>
				</div>
			</div>
		</div>
	);
}
