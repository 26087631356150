import { useNavigate } from 'react-router-dom';
import IntercomNonUserChat from '../chats/intercom_non_user_chat';
import IntercomUserChat from '../chats/intercom_user_chat';

export const Header = ({ title, showUserChat = true, showNonUserChat = false, hasBack = true, hasIcon = false, backNavigation = null }) => {
	const navigate = useNavigate();

	return (
		<div className="h-[60px] text-black font-bold flex justify-between items-center">
			{hasBack && <img src="/assets/icons/left_arrow.svg" className="w-[24px]" onClick={backNavigation ? backNavigation : () => navigate(-1)} />}
			{hasIcon && <img src="/assets/icons/rainbow_icon.svg" className="w-[24px]" onClick={() => navigate('/')} />}
			<p>{title}</p>
			<div className="flex justify-center items-center">
				{showNonUserChat && <IntercomNonUserChat />}
				{showUserChat && <IntercomUserChat />}
			</div>
		</div>
	);
};
