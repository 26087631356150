import { Link, useNavigate } from 'react-router-dom';
import { Header } from '../../components/navigation/header';
import { BottomMenu } from '../../components/navigation/bottom_menu';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { logout } from '../../services/auth';
import { PageLogo } from '../../components/utils/pageLogo';

export default function Menu() {
	return (
		<div className="flex flex-col justify-between p-5 h-screen pb-[80px]">
			<div>
				<Header title="Menu" hasBack={false} hasIcon={true} />
				<Link to="/profile" className="text-lg mt-[45px] mb-[20px] block">
					Profile Details
				</Link>
				<div className="w-full h-[2px] bg-lightGray mb-[10px]"></div>
				<Link to="/profile/password" className="text-lg mb-[20px] block">
					Update Password
				</Link>
				<div className="w-full h-[2px] bg-lightGray mb-[10px]"></div>
				<Link to="" className="text-lg mb-[20px] block">
					<div className="flex justify-between items-center">
						<p>Update KYC</p>
						<p className="text-gradient">Coming Soon</p>
					</div>
				</Link>
				<div className="w-full h-[2px] bg-lightGray mb-[10px]"></div>
				<Link to="" className="text-lg mb-[20px]">
					<div className="flex justify-between items-center">
						<p>E-Statements</p>
						<p className="text-gradient">Coming Soon</p>
					</div>
				</Link>
			</div>
			<div className="flex justify-center">
				<PageLogo />
			</div>
			<div>
				<div className=" mb-[20px]">
					<PrimaryButton text="Sign out" textColor="text-white" onClick={logout} />
				</div>
				<BottomMenu selected="more" />
			</div>
		</div>
	);
}
