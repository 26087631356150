import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useValidation from '../../hooks/useValidation';

import Loader from '../utils/loader';
import { getStorageToken } from '../../utils/functions/token';

import { register, registerConfirm } from '../../services/auth';

import { Input } from '../../components/inputs/text-input';
import { PrimaryButton } from '../../components/buttons/primary-button';
import PasswordInput from '../../components/inputs/password-input';
import { PincodeInput } from '../../components/inputs/pincodeInput';
import { Header } from '../../components/navigation/header';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { InstructionBubble } from '../../components/utils/InstructionBubble';
import { registerConfirmSchema, registerSchema } from '../../utils/validations/auth/auth_schema';
import { PageLogo } from '../../components/utils/pageLogo';

export default function Register() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const currentDirection = localStorage.getItem('languageDir');

	const [isLoading, setIsLoading] = useState(false);

	const [nextPage, setNextPage] = useState(false);

	const { validationErrMsg: otpValidationErrMsg, validate: otpValidate } = useValidation(registerSchema(t));

	const { validationErrMsg, validate } = useValidation(registerConfirmSchema(t));

	const [apiErrMsg, setApiErrMsg] = useState('');

	const [pincodeValues, setPincodeValues] = useState(Array(6).fill(''));

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		otp: '',
		password: '',
		affiliateCode: '',
		type: 'individual',
		confirmPassword: '',
		agreeToTerms: false,
		agreeToPrivacyPolicy: false,
	});

	const location = useLocation();

	const params = new URLSearchParams(location.search);
	const affiliateCode = params.get('affiliateCode');

	useEffect(() => {
		if (affiliateCode) {
			setFormData((prevState) => ({ ...prevState, affiliateCode }));
		}
	}, [location]);

	useEffect(() => {
		const token = getStorageToken();

		if (token) {
			navigate('/');
		}
	}, [navigate]);

	const handleCheckboxChange = (fieldName) => {
		setFormData((data) => ({ ...data, [fieldName]: !data[fieldName] }));
	};

	const handleRegister = async (event) => {
		event.preventDefault();

		const data = {
			type: formData.type,
			email: formData.email,
		};

		const isValid = otpValidate(data);
		if (!isValid) return;

		if (!isLoading) {
			setIsLoading(true);

			const response = await register(formData.type, formData.email);

			if (!response.isSuccess) {
				setApiErrMsg(t(`register.serverErrors.${response.error}`));
			} else {
				setApiErrMsg('');
				setNextPage(true);
			}
		}
		setIsLoading(false);
	};

	const handleRegisterConfirm = async (event) => {
		event.preventDefault();

		const data = {
			email: formData.email,
			type: formData.type,
			otp: pincodeValues.join(''),
			password: formData.password,
			confirmPassword: formData.confirmPassword,
			affiliateCode: formData.affiliateCode,
		};

		const isValid = validate(data);
		if (!isValid) {
			setFormData((data) => ({
				...data,
				agreeToPrivacyPolicy: false,
				agreeToTerms: false,
			}));
			return;
		}

		if (!formData.agreeToTerms || !formData.agreeToPrivacyPolicy) {
			setApiErrMsg(t('register.errors.termsAndPrivacyRequired'));
			setIsLoading(false);
			return;
		}

		if (!isLoading) {
			setIsLoading(true);

			const response = await registerConfirm(data);

			if (!response.isSuccess) {
				setApiErrMsg(response.error);
			} else {
				setApiErrMsg('');
				navigate(0);
			}
			setIsLoading(false);
		}
	};

	const conditionsValidate = (conditions) => {
		setFormData((data) => ({
			...data,
			...conditions,
		}));
	};

	return isLoading ? (
		<Loader />
	) : !nextPage ? (
		<form dir={currentDirection} onSubmit={handleRegister} className="flex flex-col justify-between h-screen p-5">
			<div>
				<Header showUserChat={false} showNonUserChat={true} hasBack={!formData.affiliateCode} />
				<h1 className="text-xl font-bolder">Join Paytora</h1>
				<p className="text-md mb-[27px]">Tell us the email address you'd like to use for all Paytora things</p>

				<Input
					onChange={(value) => {
						setFormData((data) => ({ ...data, email: value.trim() }));
					}}
					placeholder={t('register.emailPlaceholder')}
					type="text"
					value={formData.email}
				/>

				{otpValidationErrMsg && <ErrorBubble error={otpValidationErrMsg} />}
				{apiErrMsg && <ErrorBubble error={apiErrMsg} />}
			</div>

			<PageLogo />
			<div>
				<PrimaryButton text={t('register.continueButton')} className="mb-[10px]" />

				{!formData.affiliateCode && (
					<div className="flex items-center">
						<img src="/assets/icons/info.svg" className="me-[12px]" />
						<p className="text-sm">
							Already have paytora account?
							<Link to="/login" className="text-gradient font-bold ms-2">
								Login now!
							</Link>
						</p>
					</div>
				)}
			</div>
		</form>
	) : (
		<form onSubmit={handleRegisterConfirm} className="flex flex-col justify-between h-screen p-5">
			<div>
				<Header showUserChat={false} showNonUserChat={true} title="Sign up" />
				<h1 className="text-xl font-bolder mb-5">One time password code</h1>
				<p className="text-md text-darkGray">Verification has been sent to your email:</p>
				<p className="text-md">{formData.email}</p>

				<PincodeInput onChange={(value) => setPincodeValues(value)} pincode={pincodeValues} className="mt-[30px]" />

				{formData.affiliateCode && (
					<div className="text-md text-darkGray mt-[5px]">
						{/**flex flex-col items-center w-full justify-center */}
						<p className="">{t('register.affiliateLabel')}</p>
						<Input disabled={true} placeholder="Affiliate Code" type="text" value={formData.affiliateCode} />
					</div>
				)}

				<InstructionBubble
					title="Instructions to create a password"
					text="Your password must be between 8 and 12 characters that must contain at least one capital letter one lowercase letter, numbers and a
					special character as shown [# $ ! @ * & %]"
					className="mt-[5px]"
				/>

				<h1 className="text-xl font-bolder mt-[20px] mb-[20px]">Please create your own password</h1>

				<label className="text-md text-darkGray">Enter your password</label>

				<PasswordInput
					placeholder={t('register.passwordPlaceholder')}
					value={formData.password}
					onChange={(value) => {
						setFormData((data) => ({
							...data,
							password: value,
						}));
					}}
				/>

				<label className="text-md text-darkGray">Repeat the password</label>
				<PasswordInput
					value={formData.confirmPassword}
					placeholder={t('register.passwordConfirmPlaceholder')}
					onChange={(value) => {
						setFormData((data) => ({
							...data,
							confirmPassword: value,
						}));
					}}
				/>
				<div className="mt-5">
					<input
						type="checkbox"
						id="termsCheckbox"
						checked={formData.agreeToTerms}
						onChange={() => handleCheckboxChange('agreeToTerms')}
						className="me-2"
					/>
					<label htmlFor="termsCheckbox" className="ml-2">
						{t('termsAndConditions.title')}
						<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/terms', '_blank')}>
							{t('termsAndConditions.terms')}
						</span>
					</label>
				</div>
				<div className="mb-[20px]">
					<input
						type="checkbox"
						id="privacyCheckbox"
						checked={formData.agreeToPrivacyPolicy}
						onChange={() => handleCheckboxChange('agreeToPrivacyPolicy')}
						className="me-2"
					/>
					<label htmlFor="privacyCheckbox" className="ml-2">
						{t('termsAndConditions.title')}
						<span className="text-gradient cursor-pointer m-2" onClick={() => window.open('/privacy', '_blank')}>
							{t('termsAndConditions.privacy')}
						</span>
					</label>
				</div>
			</div>

			<PageLogo />

			<div className="text-center">
				{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
				{apiErrMsg && <ErrorBubble error={apiErrMsg} />}
				{otpValidationErrMsg && <ErrorBubble error={otpValidationErrMsg} />}
				<PrimaryButton text={t('register.signupButton')} className="mb-[10px]" />
			</div>
		</form>
	);
}
