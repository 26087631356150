import React from 'react';

export const Input = React.forwardRef(
	({ maxLength, value, placeholder, type, onChange, className, disabled, onKeyDown, inputMode, pattern, name }, ref) => {
		return (
			<input
				name={name}
				ref={ref} // Pass the ref to the input element
				maxLength={maxLength}
				value={value}
				// className={`bg-black opacity-[5%] text-black w-full rounded-l py-2 px-4 max-w-md flex flex-col placeholder:text-black text-center mb-5 ${className}`}
				className={`bg-lightGray w-full rounded-[6px] mb-[10px] h-[50px] p-5 text-md ${className}`}
				placeholder={placeholder}
				type={type}
				disabled={disabled}
				onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : {})}
				onChange={(e) => onChange(e.target.value)}
				inputMode={inputMode}
				pattern={pattern}
			/>
		);
	}
);
