import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchUserAccounts } from '../../store/accountsSlice';

import Loader from '../utils/loader';

import { getUserCardsBalanceAPI } from '../../services/cards';
import { getVerestroIbanBalanceAPI } from '../../services/ibans';

import { AccountDetails } from '../../components/dashboard/account_details';
import { AccountBalance } from '../../components/dashboard/account_balance';
import { CardsBalance } from '../../components/dashboard/cards_balance';
import { IbanBalance } from '../../components/dashboard/iban_balance';
import { DashboardActions } from '../../components/dashboard/dashboard_actions';

import { BottomMenu } from '../../components/navigation/bottom_menu';
import { TransactionsContainer } from '../../components/transactions/transactionsContainer';
import { DashboardHeader } from '../../components/navigation/dashboard_header';
import { getUserTransactionsAPI } from '../../services/user';

export default function Dashboard() {
	const startY = useRef(null);

	const dispatch = useDispatch();
	const { userWallets, totalBalance } = useSelector((state) => state.accounts);

	const [cardsBalance, setCardsBalance] = useState(0);

	const [ibanBalance, setIbanBalance] = useState(0);

	const [isLoading, setIsLoading] = useState(false);

	const loadDashboard = async () => {
		const cardsBalanceResponse = await getUserCardsBalanceAPI();
		if (cardsBalanceResponse.isSuccess) setCardsBalance(cardsBalanceResponse.balance);

		const ibanBalanceResponse = await getVerestroIbanBalanceAPI();

		if (ibanBalanceResponse.isSuccess) setIbanBalance(ibanBalanceResponse.data);
	};

	const handleTouchStart = (e) => {
		startY.current = e.touches[0].clientY;
	};

	const handleTouchMove = (e) => {
		const currentY = e.touches[0].clientY;
		const scrollY = window.scrollY;

		if (startY.current && currentY > startY.current && scrollY === 0) {
			// User is pulling down from the top of the page
			startY.current = null;
			window.location.reload();
		}
	};
	const [transactions, setTransactions] = useState();
	const getTransactionsByType = async () => {
		const response = await getUserTransactionsAPI('all');
		setTransactions(response.data);
	};

	useEffect(() => {
		dispatch(fetchUserAccounts());
	}, [dispatch]);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			await loadDashboard();
			await getTransactionsByType();
			setIsLoading(false);
		};

		fetchData();
	}, []);

	useEffect(() => {
		localStorage.setItem('totalBalance', totalBalance);
	}, [totalBalance]);

	return isLoading ? (
		<Loader />
	) : (
		<div onTouchMove={handleTouchMove} onTouchStart={handleTouchStart} className="bg-lighterGray p-5 pb-[100px]">
			<DashboardHeader />
			<AccountDetails />
			<div className="relative z-10">
				<AccountBalance userWallets={userWallets} />
				<CardsBalance cardsBalance={cardsBalance} />
			</div>
			<IbanBalance ibanBalance={ibanBalance} />
			<DashboardActions />
			<TransactionsContainer transactions={transactions} />

			<BottomMenu />
		</div>
	);
}
