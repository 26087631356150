import { z } from 'zod';

export const IbanDataWithdrawSchema = z
	.object({
		wireType: z.string().trim().min(2, { message: "Wire type can't be empty" }),
		receiverFullName: z.string().trim().min(2, { message: "Receiver full name can't be empty" }),
		receiverIBAN: z.string().trim().min(1, { message: "Receiver IBAN can't be empty" }),
		receiverStreetAndNumber: z.string().trim().min(1, { message: "Receiver Street And Number can't be empty" }),
		receiverPostalCode: z.string().trim().min(2, { message: "Receiver postal code can't be empty" }),
		receiverCity: z.string().trim().min(1, { message: "Receiver city can't be empty" }),
		receiverStreetAndNumber: z.string().trim().min(1, { message: "Receiver Street And Number can't be empty" }),
		receiverBIC: z.string().trim().optional(),
	})
	.refine(
		(data) => {
			console.log(data, ' the data');
			// Require receiverBIC if wireType is 'SWIFT'
			return data.wireType !== 'SWIFT' || data.receiverBIC;
		},
		{
			message: 'Receiver BIC is required',
			path: ['receiverBIC'],
		}
	);

export const IbanConfirmWithdrawSchema = z.object({
	currency: z.string().trim().min(3, { message: "Receiver full name can't be empty" }),
	reason: z.string().trim().min(1, { message: "Receiver IBAN can't be empty" }),
});
