// Login.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useValidation from '../../hooks/useValidation';

import { forgotPassword, forgotPasswordConfirm } from '../../services/auth';

import { Input } from '../../components/inputs/text-input';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { Header } from '../../components/navigation/header';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { PincodeInput } from '../../components/inputs/pincodeInput';
import { InstructionBubble } from '../../components/utils/InstructionBubble';
import PasswordInput from '../../components/inputs/password-input';

import { forgotPasswordConfirmSchema, forgotPasswordSchema } from '../../utils/validations/user/user_schema';
import Loader from '../utils/loader';
import { getStorageToken } from '../../utils/functions/token';
import { PageLogo } from '../../components/utils/pageLogo';

export default function ForgotPassword() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const { validationErrMsg, validate } = useValidation(forgotPasswordSchema);
	const { validationErrMsg: otpValidationErrMsg, validate: otpValidate } = useValidation(forgotPasswordConfirmSchema);

	const [apiErrMsg, setApiErrorMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [nextPage, setNextPage] = useState(false);

	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		otp: Array(6).fill(''),
	});

	useEffect(() => {
		const token = getStorageToken();

		if (token) {
			navigate('/');
		}
	}, [navigate]);

	const handleForgotPassword = async (event) => {
		event.preventDefault();

		const isValid = validate({ email: formData.email });
		if (!isValid) return;

		if (!isLoading) {
			setIsLoading(true);

			const response = await forgotPassword(formData.email);

			if (!response.isSuccess) {
				setApiErrorMsg(t(`login.serverErrors.${response.error}`));
			} else {
				setApiErrorMsg('');
				setNextPage(true);
			}
		}
		setIsLoading(false);
	};

	const handleForgotPasswordConfirm = async (event) => {
		event.preventDefault();

		const otpString = formData.otp.join('');

		if (otpString.length < 4) return setApiErrorMsg("One time password can't be empty");

		const isValid = otpValidate({
			email: formData.email,
			otp: formData.otp,
			password: formData.password,
			confirmPassword: formData.confirmPassword,
		});

		if (!isValid) return setApiErrorMsg('');

		if (!isLoading) {
			setIsLoading(true);

			const response = await forgotPasswordConfirm(formData.email, otpString, formData.password);

			if (!response.isSuccess) {
				setApiErrorMsg(t(`register.serverErrors.${response.error}`));
			} else {
				setApiErrorMsg('');
				navigate('/success', { state: { successMessage: 'Nice to have' } });
			}
			setIsLoading(false);
		}
	};

	return isLoading ? (
		<Loader />
	) : !nextPage ? (
		<form onSubmit={handleForgotPassword} className="flex flex-col justify-between h-screen p-5">
			<div>
				<Header showUserChat={false} showNonUserChat={true} title="Forgot Password" />

				<label className="text-md text-darkGray">Please enter your email address</label>
				<Input
					placeholder="Email Address"
					onChange={(value) => {
						setFormData((data) => ({ ...data, email: value }));
					}}
					type="text"
					value={formData.email}
				/>

				{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
				{apiErrMsg && <ErrorBubble error={apiErrMsg} />}
				{otpValidationErrMsg && <ErrorBubble error={otpValidationErrMsg} />}
			</div>
			<PageLogo />
			<div className="text-center">
				<PrimaryButton text="Continue" className="mb-[10px]" />
				<Link to="/login" className="text-lg">
					Login
				</Link>
			</div>
		</form>
	) : (
		<form onSubmit={handleForgotPasswordConfirm} className="flex flex-col justify-between h-screen p-5">
			<div>
				<Header showUserChat={false} showNonUserChat={true} title="Forgot Password" />
				<h1 className="text-xl font-bolder mb-5">One time password code</h1>
				<p className="text-md text-darkGray">Verification has been sent to your email:</p>
				<p className="text-md">{formData.email}</p>

				<PincodeInput onChange={(value) => setFormData((data) => ({ ...data, otp: value }))} pincode={formData.otp} className="mt-[30px]" />

				<InstructionBubble
					title="Instructions to create a password"
					text="Your password must be between 8 and 12 characters that must contain at least one capital letter one lowercase letter, numbers and a
						special character as shown [# $ ! @ * & %]"
					className="mt-[40px]"
				/>

				<h1 className="text-xl font-bolder mt-[20px] mb-[20px]">Please create a new password</h1>

				<label className="text-md text-darkGray">Enter new password</label>
				<PasswordInput value={formData.password} onChange={(value) => setFormData((data) => ({ ...data, password: value }))} placeholder="Password" />

				<label className="text-md text-darkGray">Repeat the password</label>
				<PasswordInput
					value={formData.confirmPassword}
					onChange={(value) => setFormData((data) => ({ ...data, confirmPassword: value }))}
					placeholder="Confirm Password"
				/>
			</div>
			<div className="flex justify-center">
				<PageLogo />
			</div>
			<div className="text-center">
				{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
				{apiErrMsg && <ErrorBubble error={apiErrMsg} />}
				{otpValidationErrMsg && <ErrorBubble error={otpValidationErrMsg} />}
				<PrimaryButton text="Reset Password" className="mb-[10px]" />
			</div>
		</form>
	);
}
