import { useEffect, useState } from 'react';
import { decodeToken } from 'react-jwt';

import { getVerestroIbanAPI, getVerestroIbanBalanceAPI } from '../../services/ibans';

import { useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import { getIbanTransactions } from '../../services/transactions';
import { Header } from '../../components/navigation/header';
import { InstructionBubble } from '../../components/utils/InstructionBubble';
import { AccountDetailsSubHeader } from '../../components/navigation/account_details_sub_header';
import { GradientButton } from '../../components/buttons/gradient_button';
import { TransactionsContainer } from '../../components/transactions/transactionsContainer';
import Loader from '../utils/loader';
import { getUserTransactionsAPI } from '../../services/user';

export default function IbanTransactions() {
	const navigate = useNavigate();

	const token = localStorage.getItem('token');
	const decodedToken = decodeToken(token);
	const fullname = decodedToken.firstName + ' ' + decodedToken.lastName;

	const [isLoading, setIsLoading] = useState(false);

	const [ibanData, setIbanData] = useState(null);

	const [ibanBalance, setIbanBalance] = useState(0);

	const [transactions, setTransactions] = useState([]);

	const getVerestroIBANDetails = async () => {
		// add error when not agreed
		if (!isLoading) {
			setIsLoading(true);
			const response = await getVerestroIbanAPI();
			if (!response.isSuccess) {
				setIsLoading(false);
				return;
			}
			setIbanData(response.data);
			const ibanBalanceResponse = await getVerestroIbanBalanceAPI();

			if (ibanBalanceResponse.isSuccess) setIbanBalance(ibanBalanceResponse.data);
		}
		setIsLoading(false);
	};

	const fetchTransactions = async () => {
		const transactions = await getUserTransactionsAPI('iban');

		if (transactions.isSuccess) setTransactions(transactions.data);
	};

	useEffect(() => {
		fetchTransactions();
		getVerestroIBANDetails();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className={`p-5 bg-lighterGray ${!ibanData && 'h-screen'}`}>
			<Header title={`IBAN Transactions`} />
			<AccountDetailsSubHeader />
			{!ibanData ? (
				<p className="text-center mt-5">We are still processing your IBAN request it can take a while</p>
			) : (
				<div>
					<InstructionBubble
						title="Please be advised"
						text={
							<div>
								<h1 className="text-md text-center font-bold mb-1">You can receive funds to your iban account by the following</h1>
								<ul className="text-start">
									<li>- Your employer (most be from coporate/companies)</li>
									<li>- Account own by you</li>
									<li>- Cash (by any money transfer institue (exchange))</li>
								</ul>
							</div>
						}
					/>

					<div className="flex justify-between bg-lightGray p-5 mt-[10px] rounded-[10px] w-full">
						<div>
							<p className="text-sm text-darkGray">IBAN Account Balance</p>
							<h1 className="text-xxl text-black">€{numeral(ibanBalance).format('0,0.00')}</h1>
						</div>
					</div>

					<div className="flex justify-between mt-[20px]">
						<GradientButton
							text="Send"
							className="me-2"
							iconPath="icons/arrow_out.svg"
							onClick={() => navigate('/send', { state: { isIban: true } })}
						/>
						<GradientButton text="Deposit" iconPath="icons/arrow_in.svg" onClick={() => navigate('/deposit', { state: { isIban: true } })} />
					</div>

					<div>
						<h1 className="text-lg font-bolder mt-[40px]">Your IBAN account details</h1>
						<p className="text-sm text-darkGray mt-[20px]">Account holder name</p>
						<p className="mb-[20px]">{fullname}</p>
						<p className="text-sm text-darkGray">IBAN</p>
						<p className="mb-[20px]">{ibanData ? ibanData.iban : ''}</p>
						<p className="text-sm text-darkGray">BIC/SWIFT</p>
						<p className="mb-[20px]">{ibanData ? ibanData.bic : ''}</p>
						<p className="text-sm text-darkGray">Bank Name</p>
						<p className="mb-[20px]">Bank Polska Kasa Opieki Spolka Akcyjna</p>
						<p className="text-sm text-darkGray">Address</p>
						<p className="mb-[20px]">al. Wojska Polskiego 1</p>
						<p className="text-sm text-darkGray">City</p>
						<p className="mb-[20px]">Szczecin</p>
						<p className="text-sm text-darkGray">Zip</p>
						<p className="mb-[20px]">70-470</p>
						<p className="text-sm text-darkGray">Country</p>
						<p className="mb-[20px]">Poland (PL)</p>
					</div>

					<TransactionsContainer transactions={transactions} />
				</div>
			)}
		</div>
	);
}
