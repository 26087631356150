import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import numeral from 'numeral';

import Loader from '../utils/loader';

import { swapCurrencyAPI } from '../../services/swap';

import { SwapSchema } from '../../utils/validations/user-schema';

import { Input } from '../../components/inputs/text-input';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { useSelector } from 'react-redux';
import useValidation from '../../hooks/useValidation';

import { Header } from '../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../components/navigation/account_details_sub_header';
import { InstructionBubble } from '../../components/utils/InstructionBubble';
import { AccountBalance } from '../../components/dashboard/account_balance';
import { CurrencySwapComponent } from '../../components/swap/currency_swap';
import { CustomModal } from '../../components/modals/custom_modal';
import { PageLogo } from '../../components/utils/pageLogo';
import { ErrorBubble } from '../../components/utils/errorBubble';

const customStyles = {
	control: (provided) => ({
		...provided,
		backgroundColor: '#0000000D',
		borderRadius: '10px',
		border: '0px solid black',
	}),

	indicatorSeparator: () => ({
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: 'black',
	}),
	input: (provided) => ({
		...provided,
		color: 'black',
	}),
	placeholder: (provided) => ({
		...provided,
		color: 'black',
	}),
	valueContainer: (provided) => ({
		...provided,
		height: '50px',
		display: 'flex',
		alignItems: 'center',
	}),
};

const fromCurrencies = ['USD', 'EUR'];
const toCurrencies = ['EUR', 'USD'];

export default function SwapCurrency() {
	const navigate = useNavigate();

	const { userWallets } = useSelector((state) => state.accounts);

	const [fromAmount, setFromAmount] = useState(0);
	const [toAmount, setToAmount] = useState(0);

	const [fromCurrency, setFromCurrency] = useState(fromCurrencies[0]);
	const [toCurrency, setToCurrency] = useState(toCurrencies[0]);

	const [isLoading, setIsLoading] = useState(false);

	const [countdown, setCountdown] = useState(60); // Initial countdown value
	const [intervalId, setIntervalId] = useState(null);

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const getRates = async (amount) => {
		if (amount < 100) return setToAmount(0);

		if (!isLoading) {
			const response = await swapCurrencyAPI({ fromCurrency, toCurrency, amount });

			if (!response.isSuccess) {
				setIsLoading(false);
				return;
			}
			setToAmount(response.data);
		}
	};

	const swap = async () => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await swapCurrencyAPI({
				fromCurrency,
				toCurrency,
				amount: parseFloat(fromAmount),
				confirm: true,
			});

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error ?? 'Something went wrong please try again later or contact support!');
			}
			setIsLoading(false);
			setIsSuccessModalOpen(true);
		}
	};

	useEffect(() => {
		let secondsLeft = countdown;

		const id = setInterval(() => {
			secondsLeft--;
			setCountdown(secondsLeft);

			if (secondsLeft <= 0) {
				setCountdown(60);
				if (!isSuccessModalOpen) {
					getRates(fromAmount);
					// setFromAmount(0);
					// setToAmount(0);
				}

				secondsLeft = 60; // Reset the countdown value after getting rates
			}
		}, 1000);

		setIntervalId(id);

		// getRates(fromAmount);

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [fromAmount]);

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between p-5 h-screen">
			<div>
				<Header title="Swap Currency" />
				<AccountDetailsSubHeader />
				<InstructionBubble title="Please Note" text="Minimum transfer amount $100" />

				<AccountBalance userWallets={userWallets} />
			</div>
			<div>
				<div className="flex flex-col text-center mt-[20px]">
					<h1 className="text-lg font-bolder mb-[10px]">Swap Currency</h1>

					<div className="relative ">
						<div
							className="absolute right-[50%] top-[3rem] z-10"
							onClick={() => {
								setFromCurrency(toCurrency);
								setToCurrency(fromCurrency);
								setFromAmount(0);
								setToAmount(0);
							}}
						>
							<img src="/assets/icons/input_swap_currencies.svg" className="" />
						</div>
					</div>

					<div className="relative mb-[10px]">
						<p className="text-sm absolute ms-[20px] mt-2 text-darkGray">From</p>
						<input
							name="fromAmount"
							value={fromAmount}
							className="bg-white text-black placeholder:text-black text-xl border-2 border-lightGray w-full ps-[20px] pt-[20px] pb-[10px] rounded-[10px]"
							placeholder="0.00"
							type="number"
							maxLength={10}
							onChange={(e) => {
								const value = e.target.value;
								if (value.length <= 10) {
									setFromAmount(value);
									getRates(value);
								}
							}}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<div className="absolute right-[0.6rem] top-[0.45rem]">
							<ReactSelect
								value={{
									value: fromCurrency,
									label: (
										<div className="flex items-center">
											<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">$</div>
											{fromCurrency}
										</div>
									),
								}}
								onChange={async (value) => {
									setFromCurrency(value.value);
									setToAmount(0);
									setFromAmount(0);
									// await getRates(fromAmount);
								}}
								options={fromCurrencies.map((opt) => ({
									value: opt,
									label: (
										<div className="flex">
											<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">
												{opt === 'USD' ? '$' : '€'}
											</div>
											{opt}
										</div>
									),
								}))}
								styles={customStyles}
							/>
						</div>
					</div>

					<div className="relative">
						<p className="text-sm absolute ms-[20px] mt-2 text-darkGray">To</p>
						<input
							disabled={true}
							name="toAmount"
							value={toAmount}
							className="bg-white text-black placeholder:text-black text-xl border-2 border-lightGray w-full ps-[20px] pt-[20px] pb-[10px] rounded-[10px]"
							placeholder="0.00"
							type="text"
							maxLength={10}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<div className="absolute right-[0.6rem] top-[0.45rem]">
							<ReactSelect
								value={{
									value: toCurrency,
									label: (
										<div className="flex items-center">
											<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">€</div>
											{toCurrency}
										</div>
									),
								}}
								onChange={(value) => {
									setToCurrency(value.value);
									setToAmount(0);
									setFromAmount(0);
								}}
								options={toCurrencies.map((opt) => ({
									value: opt,
									label: (
										<div className="flex">
											<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">
												{opt === 'USD' ? '$' : '€'}
											</div>
											{opt}
										</div>
									),
								}))}
								styles={customStyles}
							/>
						</div>
					</div>
				</div>

				<p className="text-gradient font-bold mt-5">Rates will updated in: {countdown} seconds hurry up to exchange!</p>
				<div className="flex justify-center p-5">
					<PageLogo className="!h-[100px]" />
				</div>
			</div>
			<div>
				{errMsg && <ErrorBubble error={errMsg} />}
				<PrimaryButton text="Swap Now" textColor="text-white" iconPath="icons/button_swap_currencies.svg" onClick={swap} className="mb-5" />
			</div>

			<CustomModal
				isOpen={isSuccessModalOpen}
				title="Successfully Exchange"
				subTitle="Your money has been exchanged!"
				children={
					<div>
						<p className="text-xxl text-center mb-5">
							<div className="flex justify-center items-center">
								<p className="text-red">
									{fromCurrency === 'USD' ? '$' : '€'} {numeral(fromAmount).format('0,0.00')}
								</p>
								<img src="/assets/icons/exchange_horizontal.svg" className="w-[20px] me-5 ms-5" />
								<p className="text-green">
									{toCurrency === 'USD' ? '$' : '€'} {numeral(toAmount).format('0,0.00')}
								</p>
							</div>
						</p>
						<PrimaryButton
							text="Close"
							onClick={() => {
								setIsSuccessModalOpen(false);
								navigate('/');
							}}
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
}
