import { Link } from 'react-router-dom';

export default function OnlyMobilePage() {
	return (
		<div className="h-screen bg-black">
			<div className="flex flex-col items-center">
				<img alt="404" src="/assets/welcome/homeIcon.svg" className="w-[10%] mt-36"></img>

				<h1 className="text-gradient text-2xl text-center p-10">This website is only accessible on mobile devices </h1>

				<div className="text-gradient text-xl text-center">
					<p>Please use your mobile phone to enter this website</p>

					<p className="mt-5">You can also download our app</p>
					<div className="flex justify-center items-center">
						<a href="https://play.google.com/store/apps/details?id=com.paytora.app">
							<img src="/assets/application/google_store.png" className="w-[150px]" />
						</a>
						<a href="https://apps.apple.com/il/app/paytora-digital-bank/id6612336703?l=he">
							<img src="/assets/application/apple_store.png" className="w-[150px]" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
