import { useEffect, useState } from 'react';

import numeral from 'numeral';

import { getDecodedToken } from '../../utils/functions/token';

export const AccountDetailsSubHeader = ({ showBalance = true, className, showAmount = false, amount = 0 }) => {
	const [accountNumber, setAccountNumber] = useState('-');
	const [totalBalance, setTotalBalance] = useState(0);

	useEffect(() => {
		const decodedToken = getDecodedToken();

		setAccountNumber(decodedToken?.customerNumber ?? '');
		setTotalBalance(localStorage.getItem('totalBalance') ?? 0);
	}, []);

	return (
		<div className={`flex justify-between mb-[40px] ${className}`}>
			<div>
				<p className="text-sm">Paytora Digital Account Number</p>
				<p className="text-lg">{accountNumber}</p>
			</div>
			{showBalance && (
				<div>
					<p className="text-sm">All your balances in € value</p>
					<p className="text-lg text-end">€{numeral(totalBalance).format('0,0.00')}</p>
				</div>
			)}
			{showAmount && (
				<div>
					<p className="text-sm">Amount you'll receive</p>
					<p className="text-lg text-end text-red">€{amount}</p>
				</div>
			)}
		</div>
	);
};
