import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { swapCurrencyAPI } from '../../services/swap';
import { PrimaryButton } from '../buttons/primary-button';
import { PageLogo } from '../utils/pageLogo';
import Loader from '../../pages/utils/loader';
import { CustomModal } from '../modals/custom_modal';
import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';
import { ErrorBubble } from '../utils/errorBubble';

const customStyles = {
	control: (provided) => ({
		...provided,
		backgroundColor: '#0000000D',
		borderRadius: '10px',
		border: '0px solid black',
	}),

	indicatorSeparator: () => ({
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: 'black',
	}),
	input: (provided) => ({
		...provided,
		color: 'black',
	}),
	placeholder: (provided) => ({
		...provided,
		color: 'black',
	}),
	valueContainer: (provided) => ({
		...provided,
		height: '50px',
		display: 'flex',
		alignItems: 'center',
	}),
};

const fromCurrencies = ['USD', 'EUR'];
const toCurrencies = ['EUR', 'USD'];

export const CurrencySwapComponent = () => {
	const navigate = useNavigate();

	const [fromAmount, setFromAmount] = useState(0);
	const [toAmount, setToAmount] = useState(0);

	const [fromCurrency, setFromCurrency] = useState(fromCurrencies[0]);
	const [toCurrency, setToCurrency] = useState(toCurrencies[0]);

	const [isLoading, setIsLoading] = useState(false);

	const [countdown, setCountdown] = useState(60); // Initial countdown value
	const [intervalId, setIntervalId] = useState(null);

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const getRates = async (amount) => {
		if (amount < 100) return;

		if (!isLoading) {
			const response = await swapCurrencyAPI({ fromCurrency, toCurrency, amount });

			if (!response.isSuccess) {
				setIsLoading(false);
				return;
			}

			setToAmount(response.data);
		}
	};

	const swap = async () => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await swapCurrencyAPI({
				fromCurrency,
				toCurrency,
				amount: parseFloat(fromAmount),
				confirm: true,
			});

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error ?? 'Something went wrong please try again later or contact support!');
			}
			setIsLoading(false);
			setIsSuccessModalOpen(true);
		}
	};

	useEffect(() => {
		// if (fromAmount > 0) {
		let secondsLeft = countdown;

		const id = setInterval(() => {
			secondsLeft--;
			setCountdown(secondsLeft);

			if (secondsLeft <= 0) {
				setCountdown(60);
				getRates(fromAmount);
				secondsLeft = 60; // Reset the countdown value after getting rates
			}
		}, 1000);

		setIntervalId(id);

		getRates(fromAmount);

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [fromAmount]);

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<div className="flex flex-col text-center mt-[20px]">
				<h1 className="text-lg font-bolder mb-[10px]">Swap Currency</h1>

				<div className="relative ">
					<div className="absolute right-[50%] top-[3rem] z-10">
						<img src="/assets/icons/input_swap_currencies.svg" className="" />
					</div>
				</div>

				<div className="relative mb-[10px]">
					<p className="text-sm absolute ms-[20px] mt-2 text-darkGray">From</p>
					<input
						name="fromAmount"
						value={fromAmount}
						className="bg-white text-black placeholder:text-black text-xl border-2 border-lightGray w-full ps-[20px] pt-[20px] pb-[10px] rounded-[10px]"
						placeholder="0.00"
						type="number"
						maxLength={10}
						onChange={(e) => {
							const value = e.target.value;
							if (value < 100) setToAmount(0);
							if (value.length <= 10) {
								setFromAmount(value);
								getRates(value);
							}
						}}
						inputMode="numeric"
						pattern="[0-9]"
					/>

					<div className="absolute right-[0.6rem] top-[0.45rem]">
						<ReactSelect
							defaultValue={{
								value: fromCurrencies[0],
								label: (
									<div className="flex items-center">
										<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">$</div>
										{fromCurrencies[0]}
									</div>
								),
							}}
							onChange={(value) => {
								setFromCurrency(value.value);
								setToAmount(0);
							}}
							options={fromCurrencies.map((opt) => ({
								value: opt,
								label: (
									<div className="flex">
										<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">{opt === 'USD' ? '$' : '€'}</div>
										{opt}
									</div>
								),
							}))}
							styles={customStyles}
						/>
					</div>
				</div>

				<div className="relative">
					<p className="text-sm absolute ms-[20px] mt-2 text-darkGray">To</p>
					<input
						disabled={true}
						name="toAmount"
						value={toAmount}
						className="bg-white text-black placeholder:text-black text-xl border-2 border-lightGray w-full ps-[20px] pt-[20px] pb-[10px] rounded-[10px]"
						placeholder="0.00"
						type="text"
						maxLength={10}
						inputMode="numeric"
						pattern="[0-9]"
					/>

					<div className="absolute right-[0.6rem] top-[0.45rem]">
						<ReactSelect
							defaultValue={{
								value: toCurrencies[0],
								label: (
									<div className="flex items-center">
										<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">€</div>
										{toCurrencies[0]}
									</div>
								),
							}}
							onChange={(value) => {
								setToCurrency(value.value);
							}}
							options={toCurrencies.map((opt) => ({
								value: opt,
								label: (
									<div className="flex">
										<div className="bg-black rounded-full p-1 w-[28px] h-[28px] text-white me-2 text-md text-center">{opt === 'USD' ? '$' : '€'}</div>
										{opt}
									</div>
								),
							}))}
							styles={customStyles}
						/>
					</div>
				</div>
			</div>

			<p className="text-gradient font-bold mt-5">Rates will updated in: {countdown} seconds hurry up to exchange!</p>
			<div className="flex justify-center p-5">
				<PageLogo />
			</div>
			{errMsg && <ErrorBubble error={errMsg} />}
			<PrimaryButton text="Swap Now" textColor="text-white" iconPath="icons/button_swap_currencies.svg" onClick={swap} className="mb-5" />

			<CustomModal
				isOpen={isSuccessModalOpen}
				title="Successfully Exchange"
				subTitle="Your money has been exchanged!"
				children={
					<div>
						<p className="text-xxl text-center p-5 text-green">
							{fromCurrency === 'USD' ? '$' : '€'}
							{numeral(fromAmount).format('0,0.00')}
						</p>
						<PrimaryButton
							text="Close"
							onClick={() => {
								setIsSuccessModalOpen(false);
								navigate('/');
							}}
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
};
