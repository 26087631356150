import { useNavigate } from 'react-router-dom';
import { GradientButton } from '../buttons/gradient_button';

export const DashboardActions = () => {
	const navigate = useNavigate();

	return (
		<div className="mb-[40px]">
			<h1 className="text-lg font-bolder mt-[40px] mb-[10px]">Actions</h1>
			<div className="flex space-x-2 text-lg">
				<GradientButton text="Send" onClick={() => navigate('/send')} iconPath="icons/arrow_out.svg" />
				<GradientButton text="Deposit" onClick={() => navigate('/deposit')} iconPath="icons/arrow_in.svg" />
			</div>

			<GradientButton text="Card To Card" onClick={() => navigate('/card-to-card')} iconPath="icons/card_to_card.svg" />
			<GradientButton text="Swap Currencies" onClick={() => navigate('/swap-currency')} iconPath="icons/button_swap_currencies_black.svg" />
		</div>
	);
};
