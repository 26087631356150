import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getMessagesAPI, updateUnreadMessagesAPI } from '../../services/messages';

import { convertDateToString } from '../../utils/functions/date';

import { Header } from '../../components/navigation/header';
import Loader from '../utils/loader';

export default function Notifications() {
	const location = useLocation();
	const { hasMessages } = location.state || false;

	const [messages, setMessages] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			if (!isLoading) {
				setIsLoading(true);
				const userMessages = await getMessagesAPI();
				setMessages(userMessages.data);
			}

			if (hasMessages) {
				await updateUnreadMessagesAPI();
			}

			setIsLoading(false);
		};

		fetchData();
	}, [hasMessages]);

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5">
			<Header title="Notfications" />
			<div>
				{messages.length > 0 ? (
					messages.map((message) => (
						<div className="flex mb-[20px]">
							<img src="/assets/icons/notification_message.svg" className="me-[20px]" />

							<div className="flex flex-col">
								<p className={`text-md ${!message.isRead ? 'font-bolder' : ''}`}>{message.content}</p>
								<p className="text-sm">{convertDateToString(message.createdAt)}</p>
							</div>
						</div>
					))
				) : (
					<div className="text-center text-xl mt-10">You don't have any notifications yet.</div>
				)}
			</div>
		</div>
	);
}
