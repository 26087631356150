import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { getDecodedToken } from '../../utils/functions/token';
import { useTranslation } from 'react-i18next';

export default function IntercomNonUserChat() {
	// Fetch the decoded token
	const decodedToken = getDecodedToken();

	const { t } = useTranslation();

	const currentDirection = localStorage.getItem('languageDir');

	useEffect(() => {
		// Initialize Intercom with custom launcher
		Intercom({
			app_id: 'jbfiwclz',
			custom_launcher_selector: '#intercom-custom-launcher',
			hide_default_launcher: true,
			language_override: localStorage.getItem('language'),
		});
	}, [decodedToken]);

	return (
		<div dir={currentDirection} id="intercom-custom-launcher" className="intercom-custom-launcher">
			<img src="/assets/icons/chat.svg" />
		</div>
	);
}
