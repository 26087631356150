import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/navigation/header';
import { PageLogo } from '../../components/utils/pageLogo';

export default function ResultPage({
	isSuccess = false,
	// successMessage = 'Your action has been successfully submitted!',
	// failureMessage = 'Your action has been failed!',
}) {
	const navigate = useNavigate();

	// const location = useLocation();

	// const { successMessage } = location.state || 'Your action has been successfully submitted!';

	useEffect(() => {
		const redirectTimer = setTimeout(() => {
			navigate('/');
		}, 3000); // 3 seconds

		return () => {
			clearTimeout(redirectTimer);
		};
	}, [navigate]);

	return (
		<div className="p-5">
			<Header hasIcon={true} hasBack={false} showUserChat={false} />
			<div className="flex justify-center mt-20 mb-20">
				<PageLogo />
			</div>
			<div className="flex justify-center items-center">
				{isSuccess ? (
					<div className="flex flex-col items-center">
						<img src="/assets/icons/check.svg" className="w-[30%]" />
						<h1 className="text-lg font-bolder mt-5 mb-2">Your action has been successfully submitted</h1>
						<p className="text-md">You will redirect to the home page immediantly</p>
					</div>
				) : (
					<div className="flex flex-col items-center">
						<img src="/assets/icons/x_sign.svg" className="w-[30%]" />
						<h1 className="text-lg font-bolder mt-5 mb-2">Your action has been failed!</h1>
						<p className="text-md">You will redirect to the home page immediantly</p>
					</div>
				)}
			</div>
		</div>
	);
}
