import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Header } from '../../../components/navigation/header';
import SelectInput from '../../../components/inputs/select-input';
import { Input } from '../../../components/inputs/text-input';
import { PrimaryButton } from '../../../components/buttons/primary-button';
import { SelectButton } from '../../../components/buttons/select_button';
import { ErrorBubble } from '../../../components/utils/errorBubble';

import Loader from '../../utils/loader';
import { getDecodedToken } from '../../../utils/functions/token';
import NotFound404 from '../../utils/404';

import { cardTopUpAccountAPI, otherTopUpAccountAPI } from '../../../services/transactions';
import { getUserCardsAPI } from '../../../services/cards';
import { getFeeByType } from '../../../services/fees';
import { PageLogo } from '../../../components/utils/pageLogo';
import { CustomModal } from '../../../components/modals/custom_modal';
import numeral from 'numeral';
import { PincodeModal } from '../../../components/modals/pincodeModal';

export default function DepositPage() {
	const navigate = useNavigate();

	const location = useLocation();
	const { currency, isIban } = location.state || {};

	const hasIban = localStorage.getItem('hasIban') === 'true';

	const [userCards, setUserCards] = useState([]);

	const toMethods = [
		{
			label: 'Paytora Digital USD Account',
			value: 'paytora_usd',
		},
		{
			label: 'Paytora Digital EUR Account',
			value: 'paytora_eur',
		},
		...(userCards.length > 0 ? [{ label: 'Lifeup Card', value: 'card' }] : []),
		...(hasIban ? [{ label: 'IBAN Account', value: 'iban' }] : []), // Add IBAN only if hasIban is true
	];

	const fromMethods = [
		{
			method: 'paytora_eur',
			options: [
				...(userCards.length > 0 ? [{ label: 'Lifeup Card', value: 'card' }] : []),
				...(hasIban ? [{ label: 'IBAN', value: 'iban' }] : []),
				{ label: 'Other Options', value: 'other' },
			],
		},
		{
			method: 'paytora_usd',
			options: [{ label: 'Other Options', value: 'other' }],
		},
		{
			method: 'iban',
			options: [
				...(userCards.length > 0 ? [{ label: 'Lifeup Card', value: 'card' }] : []),
				{ label: 'Paytora Digital EUR Account', value: 'paytora_eur' },
				{ label: 'Wire Transfer', value: 'wire' },
			],
		},
		{
			method: 'card',
			options: [
				{ label: 'Paytora Digital EUR Account', value: 'paytora_eur' },
				...(hasIban ? [{ label: 'IBAN', value: 'iban' }] : []),
				{ label: 'Wire Transfer', value: 'card_wire' },
			],
		},
	];

	const [selectedToMethod, setSelectedToMethod] = useState(isIban ? 'iban' : currency === 'EUR' ? 'paytora_eur' : 'paytora_usd');
	const [selectedFromMethod, setSelectedFromMethod] = useState(null);

	const [selectedCard, setSelectedCard] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const [amount, setAmount] = useState(null);

	const [accountNumber, setAccountNumber] = useState('-');

	const [totalFee, setTotalFee] = useState(0);
	const [pTotalFee, setPtotalFee] = useState(0);

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(true);

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const depositHandler = async () => {
		if (!amount || amount <= 0) return setErrMsg('Please enter a valid amount');

		if (!selectedFromMethod) return setErrMsg('Please seelct a payment method');

		if (!isLoading) {
			setIsLoading(true);
			if (selectedFromMethod === 'other') {
				const currency = selectedToMethod === 'paytora_usd' ? 'USD' : selectedToMethod === 'paytora_eur' ? 'EUR' : null;
				const response = await otherTopUpAccountAPI({ amount, currency });
				if (!response.isSuccess) {
					setErrMsg(response.error ?? 'Something went wrong please try again later.');
				} else {
					setErrMsg('');
					window.open(response.data, '_self');
				}
				return setIsLoading(false);
			}
			if (selectedFromMethod === 'wire') {
				setIsLoading(false);
				// get the iban details
				return navigate('/iban/details');
			}
			if (selectedFromMethod === 'card_wire') {
				setIsLoading(false);
				// get the card iban details
				return navigate('/iban/details', { state: { cardId: selectedCard, isCard: true } });
			}

			const response = await cardTopUpAccountAPI({
				toMethod: selectedToMethod === 'paytora_eur' ? 'paytora' : selectedToMethod,
				fromMethod: selectedFromMethod === 'paytora_eur' ? 'paytora' : selectedFromMethod,
				amount: parseInt(amount),
				cardId: selectedFromMethod === 'card' || selectedToMethod === 'card' ? selectedCard : null,
			});

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error ?? 'An error happend please contact support');
			}

			setIsSuccessModalOpen(true);

			setIsLoading(false);
		}
	};

	const getUserCards = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await getUserCardsAPI();
			if (response.isSuccess) {
				setUserCards(response.cards);
				setSelectedCard(response.cards[0]._id);
			}
		}
		setIsLoading(false);
	};

	const getFeesByTypeAndMethod = async (fromMethod) => {
		setPtotalFee(0);
		setTotalFee(0);
		let type = null;
		if (selectedToMethod === 'paytora_eur') {
			type = fromMethod === 'card' ? 'paytora_deposit_from_card' : fromMethod === 'iban' ? 'paytora_deposit_from_iban' : null;
		}

		if (selectedToMethod === 'card') {
			type = fromMethod === 'paytora_eur' ? 'card_deposit_from_paytora' : fromMethod === 'iban' ? 'card_deposit_from_iban' : null;
		}
		if (selectedToMethod === 'iban') {
			type = fromMethod === 'card' ? 'iban_deposit_from_card' : fromMethod === 'paytora_eur' ? 'iban_deposit_from_paytora' : null;
		}

		if (type === null) {
			setPtotalFee(0);
			setTotalFee(0);
			return;
		}

		const feesResponse = await getFeeByType({ type });

		if (feesResponse.isSuccess) {
			feesResponse.data.forEach((fee) => {
				if (fee.kind === 'percentage') setPtotalFee(fee.cost);
				else setTotalFee(fee.cost);
			});
		}
	};

	useEffect(() => {
		const decodedToken = getDecodedToken();

		setAccountNumber(decodedToken?.customerNumber ?? '');
		if (userCards.length > 0) getUserCards();
	}, []);

	if (currency && currency !== 'USD' && currency != 'EUR') return <NotFound404 />;

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between p-5 h-screen">
			<div>
				<Header title="Deposit Money" />
				<div className="flex justify-between mb-[40px]">
					<div>
						<p className="text-sm">Paytora Digital Account Number</p>
						<p className="text-lg">{accountNumber}</p>
					</div>
					<div>
						<p className="text-sm">Amount you'll receive</p>
						<p className="text-lg text-end text-red">{totalFee > 0 && amount > 0 && `€ ${amount - (amount * pTotalFee) / 100 - totalFee}`}</p>
					</div>
				</div>

				<p className="text-lg mb-[10px]">Please select where to deposit & amount</p>
				<p className="text-darkGray">Select Account To Deposit</p>
				{
					<SelectInput
						defaultValue={{
							value: selectedToMethod,
							label: toMethods.find((method) => method.value === selectedToMethod).label,
						}}
						onChange={(value) => {
							if (!currency && !isIban) {
								setSelectedToMethod(value);
								setSelectedFromMethod(null);
							}
						}}
						options={
							currency || isIban
								? []
								: toMethods.map((opt) => ({
										value: opt.value,
										label: opt.label,
								  }))
						}
					/>
				}

				{selectedToMethod === 'card' && (
					<div>
						<p className="text-darkGray mt-[20px]">Select Card To Deposit</p>
						<SelectInput
							name="cards"
							onChange={setSelectedCard}
							options={userCards.map((card) => ({ value: card._id, label: card.pan + ' - ' + card.balance + '€' }))}
							defaultValue={{ value: userCards[0]._id, label: userCards[0].pan + ' - ' + userCards[0].balance + '€' }}
						/>
					</div>
				)}

				<p className="text-darkGray">Enter amount {selectedToMethod === 'paytora_usd' ? '($)' : '(€)'}</p>

				<Input placeholder="0.00" type="number" value={amount} onChange={(value) => setAmount(value)} inputMode="numeric" pattern="[0-9]" />

				<p className="text-lg mt-[40px] mb-[10px]">How would you like to deposit?</p>
				{fromMethods
					.filter((method) => method.method === selectedToMethod)
					.flatMap((method) =>
						method.options.map((option) => (
							<div className="flex flex-col">
								<SelectButton
									text={option.label}
									onClick={async () => {
										setSelectedFromMethod(option.value);
										await getFeesByTypeAndMethod(option.value);
									}}
									isSelected={option.value === selectedFromMethod}
									iconPath={option.value === selectedFromMethod ? 'icons/rainbow_icon.svg' : 'welcome/homeIcon.svg'}
								/>
							</div>
						))
					)}
				{selectedFromMethod === 'card' && (
					<div>
						<p className="text-darkGray mt-[20px]">Select Card To Deposit From</p>
						<SelectInput
							name="cards"
							onChange={setSelectedCard}
							options={userCards.map((card) => ({ value: card._id, label: card.pan + ' - ' + card.balance + '€' }))}
							defaultValue={{ value: userCards[0]._id, label: userCards[0].pan + ' - ' + userCards[0].balance + '€' }}
						/>
					</div>
				)}
				<div className="">
					{totalFee > 0 && amount > 0 && <p className="text-red">{`Fees: €${totalFee} ${pTotalFee > 0 ? ' + ' + pTotalFee + '%' : ''}`}</p>}
				</div>
			</div>

			<PageLogo />

			<div>
				{errMsg && <ErrorBubble error={errMsg} />}
				<PrimaryButton text="Make a deposit" textColor="text-white" iconPath="icons/arrow_in_white.svg" onClick={depositHandler} className="mb-5" />
			</div>

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => {
					setIsPincodeModalOpen(false);
					navigate(-1);
				}}
				onAction={() => setIsPincodeModalOpen(false)}
			/>

			<CustomModal
				isOpen={isSuccessModalOpen}
				title="Successfully Transaction"
				subTitle="Your transaction has been approved"
				children={
					<div>
						<p className="text-xxl text-center p-5 text-green">
							{selectedToMethod === 'paytora_usd' ? '$' : '€'}
							{numeral(amount - ((parseInt(amount) * pTotalFee) / 100 + totalFee)).format('0,0.00')}
						</p>
						<PrimaryButton
							text="Close"
							onClick={() => {
								setIsSuccessModalOpen(false);
								navigate('/');
							}}
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
}
