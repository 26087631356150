import numeral from 'numeral';
import { useEffect, useState } from 'react';
import { getDecodedToken } from '../../utils/functions/token';

export const AccountDetails = () => {
	const [accountNumber, setAccountNumber] = useState('');
	const [totalBalance, setTotalBalance] = useState(0);

	useEffect(() => {
		const decodedToken = getDecodedToken();

		setAccountNumber(decodedToken?.customerNumber ?? '');
		setTotalBalance(localStorage.getItem('totalBalance') ?? 0);
	}, []);

	return (
		<div className="flex justify-between items-center border border-darkGray w-full pl-[20px] pr-[20px] pt-[10px] pb-[10px] rounded-[10px] relative bg-white">
			<div>
				<img src="/assets/icons/card_rainbow.svg" className="w-[34px]" />
				<h1 className="text-lg font-bolder">{accountNumber}</h1>
				<p className="text-sm text-darkGray">Paytora Digital Account Number</p>
			</div>
			<div className="text-end mt-[22px]">
				<h1 className="text-xxl">€{numeral(totalBalance).format('0,0.00')}</h1>
				<p className="text-sm text-darkGray">All your balances in € value</p>
			</div>
		</div>
	);
};
