import { useEffect, useState } from 'react';

import { getUserProfileAPI } from '../../services/user';
import { Header } from '../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../components/navigation/account_details_sub_header';
import { PageLogo } from '../../components/utils/pageLogo';
import Loader from '../utils/loader';
import { useNavigate } from 'react-router-dom';

export default function UserProfile() {
	const navigate = useNavigate();

	const [userProfile, setUserProfile] = useState(null);
	const [errMsg, setErrMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const getUserProfile = async () => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await getUserProfileAPI();
			if (response.isSuccess) {
				setUserProfile(response.data);
			} else setErrMsg('Unable to load your profile, please try again later');
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getUserProfile();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5">
			<Header title="Profile Details" />
			<AccountDetailsSubHeader showBalance={false} className="mt-[10px]" />

			{userProfile && errMsg.length === 0 ? (
				<div>
					<h1 className="text-xl font-bolder mb-[20px]">Nationality & Passport</h1>

					<p className="text-darkGray text-md">Nationality</p>
					<p className="text-lg mb-[20px]">---</p>
					<p className="text-darkGray text-md">Passport Expiry Date</p>
					<p className="text-lg mb-[40px]">{userProfile.documentExpiryDate}</p>

					<h1 className="text-xl font-bolder mb-[20px]">Contact Details</h1>
					<p className="text-darkGray text-md">Full Name</p>
					<p className="text-lg mb-[20px]">{userProfile.user.firstName + ' ' + userProfile.user.lastName}</p>
					<p className="text-darkGray text-md">Phone</p>
					<p className="text-lg mb-[20px]">+{userProfile.user.phoneNumber}</p>
					<p className="text-darkGray text-md">Email ID</p>
					<p className="text-lg mb-[20px]">{userProfile.user.email}</p>

					<div className="flex justify-between mb-[20px]">
						<h1 className="text-xl font-bolder ">Address Details</h1>
						<img src="/assets/icons/edit.svg" onClick={() => navigate('/profile/address/edit')} />
					</div>
					<p className="text-darkGray text-md">Current Address</p>
					<p className="text-lg mb-[20px]">
						{(
							userProfile.street.toUpperCase() +
							' ' +
							userProfile.number +
							'/' +
							userProfile.apartment +
							', ' +
							userProfile.city.toUpperCase() +
							', ' +
							userProfile.postalCode
						)
							.toLowerCase() // Convert the entire string to lowercase first
							.split(' ') // Split by spaces to handle each word individually
							.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
							.join(' ') +
							', ' +
							userProfile.country}
					</p>

					<div className="flex justify-center">
						<PageLogo />
					</div>
				</div>
			) : (
				<p>{errMsg}</p>
			)}
		</div>
	);
}
