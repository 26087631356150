import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import IntercomUserChat from '../chats/intercom_user_chat';

import { getUnreadMessagesCountAPI } from '../../services/messages';

export const DashboardHeader = () => {
	const navigate = useNavigate();

	const [hasMessages, setHasMessages] = useState(false);

	const checkAvailableNotifications = async () => {
		const messagesResponse = await getUnreadMessagesCountAPI();
		if (messagesResponse.isSuccess) setHasMessages(messagesResponse.data > 0);
	};

	useEffect(() => {
		checkAvailableNotifications();
	});

	return (
		<div className="mb-[50%]">
			<div className="h-[60px] text-black font-bold flex justify-between items-center relative z-10">
				<img src="/assets/welcome/homeIcon.svg" className="w-[24px]" onClick={() => navigate('/')} />
				<div className="flex justify-center items-center">
					<img
						src={hasMessages ? '/assets/icons/notification_on.svg' : '/assets/icons/notification_off.svg'}
						onClick={() => navigate('/messages', { state: { hasMessages: hasMessages } })}
						className="me-[50px] mt-[5px] w-[24px]"
					/>

					<IntercomUserChat isDashboard={true} />
				</div>
			</div>
			<img
				src="/assets/dashboard/main_header_logo.svg"
				className="absolute top-0 left-0 w-full h-auto object-cover z-0"
				alt="Header background logo"
			/>
		</div>
	);
};
