import React, { useState } from 'react';

import { PrimaryButton } from '../buttons/primary-button';
import Loader from '../../pages/utils/loader';
import { PincodeInput } from '../inputs/pincodeInput';
import { CustomModal } from './custom_modal';
import { SecondaryButton } from '../buttons/secondary-button';
import { verifyUserPincode } from '../../services/pincode';

export const PincodeModal = ({ isOpen, onClose, onAction }) => {
	const [pincodeValues, setPincodeValues] = useState(Array(6).fill(''));

	const [errMsg, setErrMsg] = useState('');

	const pincodeVerify = async () => {
		const pincode = pincodeValues.join('');
		const response = await verifyUserPincode(pincode);

		if (response.isSuccess) {
			onAction();
		} else setErrMsg(response.error);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			title="Please enter your pincode"
			subTitle="Enter the secret 6 digits pincode in order to proceed"
			children={
				<div>
					<p className="text-gradient text-start mb-[20px]">Forgot your pincode? Please use the chat with us.</p>
					<div className="flex flex-col items-center">
						<PincodeInput onChange={(value) => setPincodeValues(value)} pincode={pincodeValues} className="mb-[20px]" />

						<PrimaryButton
							onClick={async () => {
								await pincodeVerify();
								setPincodeValues(Array(6).fill(''));
							}}
							text="Verify"
							textColor="text-white"
						/>
						<SecondaryButton
							onClick={() => {
								onClose();
								setPincodeValues(Array(6).fill(''));
							}}
							text="Close"
							textColor="text-black"
						/>
						<p className="text-red">{errMsg}</p>
					</div>
				</div>
			}
		/>
	);
};
