import { z } from 'zod';
import { validationErrors } from '../../text/errors.text';

export const forgotPasswordSchema = z.object({
	email: z.string().email({ message: 'Email is not valid!' }),
});

export const forgotPasswordConfirmSchema = z
	.object({
		email: z.string().email({ message: 'Email is not valid!' }),
		password: z
			.string()
			.trim()
			.min(1, { message: 'Password can not be empty!' })
			.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,12}$/, {
				message: validationErrors.password_regex,
			}),
		confirmPassword: z.string().trim().min(1, { message: 'Confirm password can not be empty!' }),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: validationErrors.password_not_match,
	});

export const ChangePasswordSchema = z
	.object({
		currentPassword: z
			.string()
			.trim()
			.min(1, { message: 'Current password can not be empty!' })
			.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,12}$/, {
				message: validationErrors.password_regex,
			}),
		newPassword: z
			.string()
			.trim()
			.min(1, { message: 'New password can not be empty!' })
			.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,12}$/, {
				message: validationErrors.password_regex,
			}),
		confirmNewPassword: z.string().trim().min(1, { message: 'Confirm new password can not be empty!' }),
	})
	.refine((data) => data.newPassword === data.confirmNewPassword, {
		message: validationErrors.password_not_match,
	});

export const SetPincodeSchema = z
	.object({
		pincode: z.string().trim().min(6, { message: 'Pincode most be 6 digits length' }),
		confirmPincode: z.string().trim().min(1, { message: 'Confirm pincode most be 6 digits length' }),
	})
	.refine((data) => data.pincode === data.confirmPincode, {
		message: 'Pincode are not matched!',
	});
