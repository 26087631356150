import { useEffect, useState } from 'react';

import { Header } from '../../components/navigation/header';
import { Input } from '../../components/inputs/text-input';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { SelectButton } from '../../components/buttons/select_button';
import { ErrorBubble } from '../../components/utils/errorBubble';

import { getDecodedToken } from '../../utils/functions/token';
import Loader from '../utils/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { PincodeModal } from '../../components/modals/pincodeModal';
import { PageLogo } from '../../components/utils/pageLogo';
import { getFeeByType } from '../../services/fees';
import { cardTopUpAccountAPI } from '../../services/transactions';
import { CustomModal } from '../../components/modals/custom_modal';
import numeral from 'numeral';

export default function CardDepositPage() {
	const navigate = useNavigate();

	const hasIban = localStorage.getItem('hasIban') === 'true';

	const fromMethods = [
		{
			label: 'From Paytora Digital EUR Account',
			value: 'paytora',
		},

		...(hasIban ? [{ label: 'From Your IBAN Account', value: 'iban' }] : []), // Add IBAN only if hasIban is true
		{
			label: 'Wire Transfer',
			value: 'wire',
		},
	];

	const location = useLocation();
	const { cardId } = location.state || {};

	const [isLoading, setIsLoading] = useState(false);

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(true);

	const [errMsg, setErrMsg] = useState('');

	const [amount, setAmount] = useState(null);

	const [selectedFromMethod, setSelectedFromMethod] = useState(null);

	const [totalFee, setTotalFee] = useState(0);
	const [pTotalFee, setPtotalFee] = useState(0);

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const [accountNumber, setAccountNumber] = useState('-');

	const depositHandler = async () => {
		if (!amount || amount <= 0) return setErrMsg('Please enter a valid amount');

		if (!selectedFromMethod) return setErrMsg('Please seelct a payment method');

		if (!isLoading) {
			setIsLoading(true);

			if (selectedFromMethod === 'wire') {
				setIsLoading(false);
				// get the iban details
				return navigate('/iban/details', { state: { cardId, isCard: true } });
			}

			const response = await cardTopUpAccountAPI({
				toMethod: 'card',
				fromMethod: selectedFromMethod,
				amount: parseInt(amount),
				cardId,
			});

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error ?? 'An error happend please contact support');
			}

			setIsSuccessModalOpen(true);

			setIsLoading(false);
		}
	};

	const getFeesByTypeAndMethod = async (fromMethod) => {
		setPtotalFee(0);
		setTotalFee(0);
		let type = fromMethod === 'paytora' ? 'card_deposit_from_paytora' : fromMethod === 'iban' ? 'card_deposit_from_iban' : null;

		if (type === null) {
			setPtotalFee(0);
			setTotalFee(0);
			return;
		}

		const feesResponse = await getFeeByType({ type });
		console.log(feesResponse, ' the response');
		if (feesResponse.isSuccess) {
			feesResponse.data.forEach((fee) => {
				if (fee.kind === 'percentage') setPtotalFee(fee.cost);
				else setTotalFee(fee.cost);
			});
		}
	};

	useEffect(() => {
		const decodedToken = getDecodedToken();

		setAccountNumber(decodedToken?.customerNumber ?? '');
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between p-5 h-screen">
			<div>
				<Header title="Card Deposit" />
				<div className="flex justify-between mb-[40px]">
					<div>
						<p className="text-sm">Paytora Digital Account Number</p>
						<p className="text-lg">{accountNumber}</p>
					</div>
					<div>
						<p className="text-sm">Amount you'll receive</p>
						<p className="text-lg text-end text-red">{totalFee > 0 && amount > 0 && `€ ${amount - (amount * pTotalFee) / 100 - totalFee}`}</p>
					</div>
				</div>

				<p className="text-darkGray">Enter amount</p>

				<Input placeholder="0.00" type="number" value={amount} onChange={(value) => setAmount(value)} inputMode="numeric" pattern="[0-9]" />

				<p className="text-lg mt-[40px] mb-[10px]">How would you like to deposit?</p>
				{fromMethods.map((method) => (
					<div className="flex flex-col">
						<SelectButton
							text={method.label}
							onClick={async () => {
								setSelectedFromMethod(method.value);
								await getFeesByTypeAndMethod(method.value);
							}}
							isSelected={method.value === selectedFromMethod}
							iconPath={method.value === selectedFromMethod ? 'icons/rainbow_icon.svg' : 'welcome/homeIcon.svg'}
						/>
					</div>
				))}

				<div className="">
					{totalFee > 0 && amount > 0 && <p className="text-red">{`Fees: €${totalFee} ${pTotalFee > 0 ? ' + ' + pTotalFee + '%' : ''}`}</p>}
				</div>
			</div>

			<PageLogo />
			<div>
				{errMsg && <ErrorBubble error={errMsg} />}
				<PrimaryButton text="Make a deposit" textColor="text-white" iconPath="icons/arrow_in_white.svg" onClick={depositHandler} className="mb-5" />
			</div>

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => {
					setIsPincodeModalOpen(false);
					navigate(-1);
				}}
				onAction={() => setIsPincodeModalOpen(false)}
			/>

			<CustomModal
				isOpen={isSuccessModalOpen}
				title="Successfully Transaction"
				subTitle="Your transaction has been approved"
				children={
					<div>
						<p className="text-xxl text-center p-5 text-green">
							€{numeral(amount - ((parseInt(amount) * pTotalFee) / 100 + totalFee)).format('0,0.00')}
						</p>
						<PrimaryButton
							text="Close"
							onClick={() => {
								setIsSuccessModalOpen(false);
								navigate('/');
							}}
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
}
