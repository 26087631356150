import { useEffect, useState } from 'react';

import { Header } from '../../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../../components/navigation/account_details_sub_header';

import { PrimaryButton } from '../../../components/buttons/primary-button';
import { Input } from '../../../components/inputs/text-input';
import SelectInput from '../../../components/inputs/select-input';
import { PageLogo } from '../../../components/utils/pageLogo';
import { getFeeByType } from '../../../services/fees';
import useValidation from '../../../hooks/useValidation';
import { IbanDataWithdrawSchema } from '../../../utils/validations/withdraw/withdraw-schema';
import { ErrorBubble } from '../../../components/utils/errorBubble';
import { withdrawalAPI } from '../../../services/ibans';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../utils/loader';
import { swapCurrencyAPI } from '../../../services/swap';

const currencies = [
	{ label: '🇷🇴 RON - NOVO LEU', value: 'RON' },
	{ label: '🇵🇱 PLN - POLAND ZLOTY', value: 'PLN' },
	{ label: '🇿🇦 ZAR - RAND (SOUTH AFRICA)', value: 'ZAR' },
	{ label: '🇸🇬 SGD - SINGAPORE DOLLAR', value: 'SGD' },
	{ label: '🇸🇪 SEK - SWEDISH KRONA', value: 'SEK' },
	{ label: '🇨🇭 CHF - SWISS FRANCS', value: 'CHF' },
	{ label: '🇹🇳 TND - TUNISIAN DINAR', value: 'TND' },
	{ label: '🇺🇸 USD - UNITED STATES DOLLAR', value: 'USD' },
	{ label: '🇹🇷 TRY - YENI TURKISH LIRA', value: 'TRY' },
	{ label: '🇨🇳 CNY - YUAN RENMINBI DA CHINA', value: 'CNY' },
	{ label: '🇩🇿 DZD - ALGERIAN DINAR', value: 'DZD' },
	{ label: '🇦🇺 AUD - AUSTRALIAN DOLLAR', value: 'AUD' },
	{ label: '🇹🇭 THB - BAHT TAILANDES', value: 'THB' },
	{ label: '🇨🇦 CAD - CANADIAN DOLLAR', value: 'CAD' },
	{ label: '🇮🇸 ISK - COROA DA ISLÂNDIA', value: 'ISK' },
	// { label: '🇨🇾 CYP - CYPRUS POUND', value: 'CYP' },
	{ label: '🇨🇿 CZK - CZECH KORUNA', value: 'CZK' },
	{ label: '🇩🇰 DKK - DANISH KRONE', value: 'DKK' },
	{ label: '🇬🇧 GBP - GREAT BRITISH POUND', value: 'GBP' },
	{ label: '🇭🇰 HKD - HONG KONG DOLLAR', value: 'HKD' },
	{ label: '🇭🇺 HUF - HUNGARIAN FORINT', value: 'HUF' },
	{ label: '🇮🇱 ILS - ISRAELI NEW SHEKEL', value: 'ILS' },
	{ label: '🇯🇵 JPY - JAPANESE YEN', value: 'JPY' },
	{ label: '🇲🇴 MOP - MACAU PATACA', value: 'MOP' },
	{ label: '🇲🇦 MAD - MAROCCAN DIRHAM', value: 'MAD' },
	{ label: '🇲🇽 MXN - MEXICAN PESO', value: 'MXN' },
	{ label: '🇳🇿 NZD - NEW ZEALAND DOLLAR', value: 'NZD' },
	{ label: '🇳🇴 NOK - NORWEGIAN KRONE', value: 'NOK' },
];

export default function Withdrawal() {
	const location = useLocation();
	const { type } = location.state || {};

	const navigate = useNavigate();

	const { validationErrMsg, validate } = useValidation(IbanDataWithdrawSchema);

	const [wireType, setWireType] = useState('SEPA');
	const [wireCurrency, setWireCurrency] = useState('EUR');

	const [errMsg, setErrMsg] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [amount, setAmount] = useState(0);
	const [currency, setCurrency] = useState('EUR');
	const [reason, setReason] = useState('');

	const [step, setStep] = useState(0);

	const [totalFee, setTotalFee] = useState(null);
	const [pTotalFee, setPtotalFee] = useState(null);

	const [exchangedAmount, setExchangedAmount] = useState(0);

	const [bankDetails, setBankDetails] = useState({
		wireType,
		receiverFullName: '',
		receiverIBAN: '',
		receiverStreetAndNumber: '',
		receiverPostalCode: '',
		receiverCity: '',
		receiverBIC: '',
	});

	const handleWireTypeChange = (event) => {
		setWireType(event.target.value);
		setWireCurrency('EUR');
		getFees(event.target.value, 'EUR');
	};

	const handleCurrencyChange = (event) => {
		setWireCurrency(event.target.value);
		getFees(wireType, event.target.value);
	};

	const getFees = async (type = wireType, currency = wireCurrency) => {
		const typeFilter =
			type === 'SWIFT' && currency === 'Other'
				? 'iban_withdraw_other_swift'
				: type === 'SWIFT'
				? 'iban_withdraw_eur_swift'
				: type === 'SEPA'
				? 'iban_withdraw_eur_sepa'
				: '';
		const feesResponse = await getFeeByType({ type: typeFilter });
		console.log(feesResponse, ' FRR');
		if (feesResponse.isSuccess) {
			feesResponse.data.forEach((fee) => {
				if (fee.kind === 'percentage') setPtotalFee(fee.cost);
				else setTotalFee(fee.cost);
			});
		}
	};

	const getRates = async (amount) => {
		if (amount < 100) return setExchangedAmount(0);

		if (!isLoading) {
			const response = await swapCurrencyAPI({ fromCurrency: currency, toCurrency: 'EUR', amount });

			if (!response.isSuccess) {
				setIsLoading(false);
				return;
			}
			setExchangedAmount(response.data);
		}
	};

	const handleBankWire = async () => {
		const data = {
			bankDetails,
			amount: wireCurrency === 'Other' ? exchangedAmount : amount,
			currency,
			reason,
			fromMethod: type,
			wireType,
		};

		if (!reason || !amount || amount <= 0) {
			return setErrMsg('Reason & Amount are required');
		}

		if (!isLoading) {
			setIsLoading(true);
			const response = await withdrawalAPI(data);

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error ?? 'An error happened please contact support');
			}
		}

		setIsLoading(false);
		navigate('/success');
	};

	useEffect(() => {
		getFees();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5 h-screen flex flex-col justify-between">
			{step === 0 && (
				<>
					<div>
						<Header title="IBAN Withdraw" />
						<AccountDetailsSubHeader />
						<h1 className="text-lg mb-[10px]">Wire Options</h1>
						<div className="flex flex-col mb-5">
							<h2 className="text-lg  mb-2">Select Wire Type</h2>
							<label className="space-x-2">
								<input type="radio" name="wireType" value="SEPA" checked={wireType === 'SEPA'} onChange={handleWireTypeChange} />
								<span>SEPA</span>
							</label>
							<label className="space-x-2">
								<input type="radio" name="wireType" value="SWIFT" checked={wireType === 'SWIFT'} onChange={handleWireTypeChange} />
								<span>SWIFT</span>
							</label>
						</div>

						<div className="flex flex-col mb-5">
							<h2 className="text-lg  mb-2">Select Currency</h2>
							<label className="space-x-2">
								<input type="radio" name="currency" value="EUR" checked={wireCurrency === 'EUR'} onChange={handleCurrencyChange} />
								<span>EUR</span>
							</label>
							<label className="space-x-2">
								<input
									type="radio"
									name="currency"
									disabled={wireType === 'SEPA'}
									value="Other"
									checked={wireCurrency === 'Other'}
									onChange={handleCurrencyChange}
								/>
								<span>Other</span>
							</label>
						</div>

						<div className="text-lg">
							<p className="font-bold">Fees will be applied</p>
							<p className="">
								{pTotalFee}% (Min. €{totalFee})
							</p>
						</div>
					</div>

					<PageLogo />
					<PrimaryButton
						text="Next"
						className="mb-[10px]"
						textColor="text-white"
						onClick={() => {
							setStep(step + 1);
							setCurrency(wireCurrency === 'EUR' ? 'EUR' : currencies[0].value);
						}}
					/>
				</>
			)}

			{step === 1 && (
				<>
					<div>
						<Header title="IBAN Withdraw" backNavigation={step === 0 ? null : () => setStep(step - 1)} />
						<AccountDetailsSubHeader />
						<h1 className="text-lg mb-[10px]">Receiver Bank Details</h1>
						<p>Receiver Full Name</p>
						<Input
							onChange={(value) => setBankDetails((data) => ({ ...data, receiverFullName: value }))}
							placeholder="Ex. John Doe"
							type="text"
							value={bankDetails.receiverFullName}
						/>
						<p>Receiver Account Number (IBAN)</p>
						<Input
							onChange={(value) => setBankDetails((data) => ({ ...data, receiverIBAN: value }))}
							placeholder="Ex. PL12345678910"
							type="text"
							value={bankDetails.receiverIBAN}
						/>
						{wireType === 'SWIFT' && (
							<div>
								<p>Receiver BIC</p>
								<Input
									onChange={(value) => setBankDetails((data) => ({ ...data, receiverBIC: value }))}
									placeholder="Ex. BZENLT22XXX"
									type="text"
									value={bankDetails.receiverBIC}
								/>
							</div>
						)}

						<p>Receiver Bank Street & Number</p>
						<Input
							onChange={(value) => setBankDetails((data) => ({ ...data, receiverStreetAndNumber: value }))}
							placeholder="Ex. Promienna 10"
							type="text"
							value={bankDetails.receiverStreetAndNumber}
						/>
						<p>Receiver Bank Postal Code</p>
						<Input
							onChange={(value) => setBankDetails((data) => ({ ...data, receiverPostalCode: value }))}
							placeholder="Ex. 20-203"
							type="text"
							value={bankDetails.receiverPostalCode}
						/>
						<p>Receiver Bank City</p>
						<Input
							onChange={(value) => setBankDetails((data) => ({ ...data, receiverCity: value }))}
							placeholder="Ex. Lublin"
							type="text"
							value={bankDetails.receiverCity}
						/>
					</div>
					<div className="flex flex-col justify-center">
						<PageLogo />
						{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
						<PrimaryButton
							text="Next"
							className="mb-[10px]"
							textColor="text-white"
							onClick={() => {
								const isValid = validate({ ...bankDetails, wireType });
								if (!isValid) return;
								setStep(step + 1);
							}}
						/>
					</div>
				</>
			)}

			{step === 2 && (
				<>
					<div>
						<Header title="IBAN Withdraw" backNavigation={step === 0 ? null : () => setStep(step - 1)} />
						<AccountDetailsSubHeader />
						<h1 className="text-lg mb-[10px]"></h1>
						<p>Transfer Currency</p>
						{wireCurrency === 'Other' ? (
							<>
								<SelectInput
									defaultValue={{ value: currencies[0].value, label: currencies[0].label }}
									onChange={(value) => {
										setCurrency(value);
										setAmount(0);
										setExchangedAmount(0);
									}}
									options={currencies.map((opt) => ({
										value: opt.value,
										label: opt.label,
									}))}
								/>
								<p className="text-md mt-2 mb-2">
									*The final choosen currency amount value will be determined upon sending the funds (proof of payment will be sent to your email
									within 24h)
								</p>
							</>
						) : (
							<Input disabled={true} value="EUR" />
						)}
						<p>Amount</p>
						<Input
							placeholder="0.00"
							type="number"
							value={amount}
							onChange={(value) => {
								setAmount(value);
								if (wireCurrency === 'Other') {
									getRates(value);
								}
							}}
							inputMode="numeric"
							pattern="[0-9]"
						/>

						<p>Transfer Reason</p>
						<Input
							onChange={(value) => {
								setReason(value);
							}}
							placeholder="Ex. Food"
							type="text"
							value={reason}
						/>
						{wireCurrency === 'Other' && (
							<div>
								{exchangedAmount > 0 && (
									<div>
										You will be charged <Input disabled={true} value={'€' + exchangedAmount} />
									</div>
								)}
								<p className="text-red text-md">Additional Conversion rate fees: FX + €5 will be applied</p>
							</div>
						)}
					</div>
					<div className="flex flex-col justify-center">
						<PageLogo />
					</div>
					<div>
						{errMsg && <ErrorBubble error={errMsg} />}
						<PrimaryButton text="Send" className="mb-[10px]" iconPath="icons/arrow_out_white.svg" textColor="text-white" onClick={handleBankWire} />
					</div>
				</>
			)}
		</div>
	);
}
