import { useEffect, useState } from 'react';

import Loader from '../utils/loader.jsx';

import { getUserCardsAPI } from '../../services/cards.js';

import { Header } from '../../components/navigation/header.jsx';
import { AccountDetailsSubHeader } from '../../components/navigation/account_details_sub_header.jsx';
import { BottomMenu } from '../../components/navigation/bottom_menu.jsx';
import { CardItem } from '../../components/cards/card_item.jsx';
import { TemplateCardItem } from '../../components/cards/template_card_item.jsx';

export default function Cards() {
	const [selectedTab, setSelectedTab] = useState('active');

	const [cards, setCards] = useState([]);
	const [cardTemplates, setCardTemplates] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	const getUserCards = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await getUserCardsAPI();
			if (response.isSuccess) {
				setCards(response.cards);
				setCardTemplates(response.templates);
				if (response.cards.length === 0) setSelectedTab('apply');
			}
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getUserCards();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className="flex flex-col justify-between h-screen p-5 ">
			<div className="pb-[100px]">
				<Header title="Cards" />
				<AccountDetailsSubHeader />

				<div className="flex text-md text-darkGray">
					<p className={`me-5 ${selectedTab === 'active' && 'font-bolder text-black'}`} onClick={() => setSelectedTab('active')}>
						Active Cards
						{selectedTab === 'active' && <div className="bg-black h-[2px] mt-[14px]"></div>}
					</p>

					<p
						className={`${selectedTab === 'apply' && 'font-bolder text-black'}`}
						onClick={async () => {
							if (cardTemplates.length === 0) return;
							setSelectedTab('apply');
						}}
					>
						Apply
						{selectedTab === 'apply' && <div className="bg-black h-[2px] mt-[14px]"></div>}
					</p>
				</div>

				{selectedTab === 'active' ? (
					<div>
						<div>
							<h1 className="text-lg text-gradient font-bolder mt-[30px]">Virtual Cards</h1>
							{cards.filter((card) => card.cardTemplate.type === 'virtual').length === 0 ? (
								<div className="mt-[10px] mb-[40px]">
									<p className="text-lg font-bolder text-center mb-[10px]">You don't have any virtual cards yet.</p>
								</div>
							) : (
								cards
									.filter((card) => card.cardTemplate.type === 'virtual')
									.map((card) => (
										<div>
											<CardItem card={card} key={card._id} />
										</div>
									))
							)}
						</div>
						<div>
							<h1 className="text-lg text-gradient font-bolder mt-[20px]">Physical Cards</h1>
							{cards.filter((card) => card.cardTemplate.type === 'physical').length === 0 ? (
								<div className="mt-[10px] mb-[40px]">
									<p className="text-lg font-bolder text-center mb-[10px]">You don't have any physical cards yet.</p>
								</div>
							) : (
								cards
									.filter((card) => card.cardTemplate.type === 'physical')
									.map((card) => (
										<div>
											<CardItem card={card} key={card._id} />
										</div>
									))
							)}
						</div>
					</div>
				) : (
					<div>
						<div>
							<h1 className="text-lg text-gradient font-bolder mt-[30px]">Order New Virtual Card</h1>
							{cardTemplates.filter((template) => template.type === 'virtual').length === 0 ? (
								<div className="mt-[10px] mb-[40px]">
									<p className="text-lg font-bolder text-center mb-[10px]">You have acquired all the virtual available cards</p>
								</div>
							) : (
								cardTemplates
									.filter((template) => template.type === 'virtual')
									.map((template) => (
										<div>
											<TemplateCardItem template={template} key={template._id} />
										</div>
									))
							)}
						</div>
						<div>
							<h1 className="text-lg text-gradient font-bolder mt-[20px]">Order New Physical Card</h1>
							{cardTemplates.filter((template) => template.type === 'physical').length === 0 ? (
								<div className="mt-[10px] mb-[40px]">
									<p className="text-lg font-bolder text-center mb-[10px]">You have acquired all the physical available cards</p>
								</div>
							) : (
								cardTemplates
									.filter((template) => template.type === 'physical')
									.map((template) => (
										<div>
											<TemplateCardItem template={template} key={template._id} />
										</div>
									))
							)}
						</div>
					</div>
				)}
			</div>
			<BottomMenu selected="cards" />
		</div>
	);
}
