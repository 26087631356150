import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './i18n';

import { isMobile } from 'react-device-detect';

import { getStorageToken, handleTokenExpiration } from './utils/functions/token';

import Login from './pages/auth/login';
import Register from './pages/auth/register.jsx';

import Dashboard from './pages/dashboard/dashboard';

import NotFound404 from './pages/utils/404';
import ResultPage from './pages/utils/result-page';
import SuspendedPage from './pages/utils/suspended';
import OnlyMobilePage from './pages/utils/onlyMobile.jsx';

import UserProfile from './pages/auth/userProfile.jsx';
import ForgotPassword from './pages/auth/forgotPassword.jsx';

import TermsAndConditions from './pages/terms/terms-and-conditions';
import PrivacyPolicy from './pages/terms/privacy-policy';
import CardTermsAndConditions from './pages/terms/card-terms-and-conditions.jsx';
import CardPrivacyPolicy from './pages/terms/card-privacy-policy.jsx';
import KYCProcess from './pages/kyc/kyc_process.jsx';

import IbanTermsAndConditions from './pages/terms/iban-terms-and-conditions.jsx';

import WelcomePage from './pages/auth/welcome.jsx';
import DepositPage from './pages/actions/deposit/deposit.jsx';
import SendPage from './pages/actions/send/send.jsx';

import SetPincode from './pages/auth/setPincode.jsx';
import Withdrawal from './pages/actions/send/withdrawal.jsx';
import Menu from './pages/menu/menu.jsx';
import Notifications from './pages/messages/messages.jsx';
import ChangePassword from './pages/auth/changePassword.jsx';

import Cards from './pages/cards/cards.jsx';
import CardDepositPage from './pages/cards/card_deposit.jsx';
import WireTransfer from './pages/actions/deposit/wire_transfer.jsx';
import PaytoraMemberTransfer from './pages/actions/send/paytora_transfer.jsx';
import IbanTransactions from './pages/transactions/ibanTransactions.jsx';
import SwapCurrency from './pages/swap/swap-currency.jsx';
import CardTransactions from './pages/transactions/cardsTransactions.jsx';
import PayToraTransactions from './pages/transactions/paytoraTransactions.jsx';
import EditProfileAddress from './pages/auth/editProfileAddress.jsx';
import CardToCard from './pages/cards/cardToCard.jsx';

const AuthenticatedRoute = ({ element, kycRequired = false, ibanRequired = false }) => {
	const token = getStorageToken();
	const kycValue = localStorage.getItem('kyc') ?? 'none';
	const suspended = JSON.parse(localStorage.getItem('isSuspended'));
	// const hasIban = JSON.parse(localStorage.getItem('hasIban'));
	const hasPincode = JSON.parse(localStorage.getItem('hasPincode'));

	useEffect(() => {
		if (token) {
			handleTokenExpiration(token);
		}
	}, [token]);

	if (!token) {
		return <WelcomePage />;
	}

	if (suspended) {
		return <SuspendedPage />;
	}

	if (kycRequired && kycValue !== 'completed') {
		return <KYCProcess />;
	}

	if (!hasPincode) {
		return <SetPincode />;
	}

	return element;
};

function App() {
	if (!isMobile) {
		return <OnlyMobilePage />;
	}

	return (
		<Router>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Register />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/terms" element={<TermsAndConditions />}></Route>
				<Route path="/privacy" element={<PrivacyPolicy />}></Route>

				<Route path="/success" element={<ResultPage isSuccess={true} />}></Route>

				<Route path="/failed" element={<ResultPage />}></Route>

				<Route path="/404" element={<NotFound404 />} />
				<Route path="*" element={<Navigate to="/404" />} />
				{/* Authenticated Routes */}

				<Route path="/" element={<AuthenticatedRoute element={<Dashboard />} kycRequired={true} />} />

				<Route path="/kyc" element={<AuthenticatedRoute element={<Dashboard />} kycRequired={true} />} />

				<Route path="/menu" element={<AuthenticatedRoute element={<Menu />} kycRequired={true} />} />
				{/** Done need to get nationality*/}
				<Route path="/profile" element={<AuthenticatedRoute element={<UserProfile />} kycRequired={true} />} />

				<Route path="/profile/address/edit" element={<AuthenticatedRoute element={<EditProfileAddress />} kycRequired={true} />} />

				<Route path="/profile/password" element={<AuthenticatedRoute element={<ChangePassword />} kycRequired={true} />} />

				<Route path="/messages" element={<AuthenticatedRoute element={<Notifications />} kycRequired={true} />} />
				<Route path="/deposit" element={<AuthenticatedRoute element={<DepositPage />} kycRequired={true} />} />
				<Route path="/send" element={<AuthenticatedRoute element={<SendPage />} kycRequired={true} />} />
				<Route path="/send/withdrawal" element={<AuthenticatedRoute element={<Withdrawal />} kycRequired={true} />} />
				<Route path="/send/paytora-member/:currencyCode?" element={<AuthenticatedRoute element={<PaytoraMemberTransfer />} kycRequired={true} />} />
				<Route path="/swap-currency" element={<AuthenticatedRoute element={<SwapCurrency />} kycRequired={true} />} />
				<Route path="/cards" element={<AuthenticatedRoute element={<Cards />} kycRequired={true} />} />
				<Route path="/card/deposit" element={<AuthenticatedRoute element={<CardDepositPage />} kycRequired={true} />} />
				<Route path="/card/terms" element={<AuthenticatedRoute element={<CardTermsAndConditions />} kycRequired={true} />} />
				<Route path="/card/privacy" element={<AuthenticatedRoute element={<CardPrivacyPolicy />} kycRequired={true} />} />
				<Route path="/card-to-card" element={<AuthenticatedRoute element={<CardToCard />} kycRequired={true} />} />
				<Route path="/iban/details" element={<AuthenticatedRoute element={<WireTransfer />} kycRequired={true} />} />
				<Route path="/iban/terms" element={<IbanTermsAndConditions />}></Route>
				<Route path="/transactions/paytora/:currency?" element={<AuthenticatedRoute element={<PayToraTransactions />} kycRequired={true} />} />
				<Route path="/transactions/cards" element={<AuthenticatedRoute element={<CardTransactions />} kycRequired={true} />} />
				<Route path="/transactions/iban" element={<AuthenticatedRoute element={<IbanTransactions />} kycRequired={true} />} />
				{/* <Route path="/top-up/other/select/:currencyCode?" element={<AuthenticatedRoute element={<TopUpOtherSelectAmount />} kycRequired={true} />} /> */}
				{/* <Route path="/transfer-request" element={<AuthenticatedRoute element={<TransferRequest />} kycRequired={true} />} /> */}
				{/* <Route path="/transfer-request/iban" element={<AuthenticatedRoute element={<IbanTransfer />} kycRequired={true} />} /> */}
				{/* <Route path="/transactions/iban/actions" element={<AuthenticatedRoute element={<IbanActionsOld />} kycRequired={true} />} /> */}
				{/* <Route
					path="/iban/transfer/member"
					element={<AuthenticatedRoute element={<IbanMemberTransfer />} kycRequired={true} ibanRequired={true} />}
				/> */}
				{/* <Route path="/iban/transfer/card" element={<AuthenticatedRoute element={<IbanCardTransfer />} kycRequired={true} ibanRequired={true} />} /> */}
				{/* <Route path="/iban/transfer/wire" element={<AuthenticatedRoute element={<BankWithdraw />} kycRequired={true} ibanRequired={true} />} /> */}
				{/* 
				<Route path="/bank-withdraw/select-type" element={<AuthenticatedRoute element={<SelectBankWithdrawType />} kycRequired={true} />} />  */}
				{/* Not In Use Routes */}
				{/* <Route path="/top-up/other" element={<AuthenticatedRoute element={<TopUpOtherPreview />} kycRequired={true} />} /> */}
				{/* <Route path="/login" element={<AuthenticatedRoute element={<Dashboard />} kycRequired={true} />} /> */}
				{/* <Route path="/merchant/payment/:merchantId" element={<MerchantPaymentHome />}></Route> */}
				{/* <Route path="/top-up/wire-transfer" element={<AuthenticatedRoute element={<TopUpWrire />} kycRequired={true} />} /> */}
				{/* <Route path="/admin-message" element={<AuthenticatedRoute element={<AdminMessage />} kycRequired={true} />} /> */}
				{/* <Route path="/transfer-request/merchant" element={<AuthenticatedRoute element={<MerchantTransfer />} kycRequired={true} />} /> */}
				{/* 
				
				*/}
			</Routes>
		</Router>
	);
}

export default App;
